// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { GetClientServiceFeesAction, GetClientServiceFeesActionTypes } from './actions';
import { ServiceFees } from '../../../../../components/services/types';

export interface GetClientServiceFeesState {
  error?: Error;
  loading: boolean;
  fees?: ServiceFees;
}

export const initialGetClientServiceFeesState: GetClientServiceFeesState = {
  loading: false,
};

export default function getClientServiceFeesReducer(
  state = initialGetClientServiceFeesState,
  action: GetClientServiceFeesAction,
) {
  switch (action.type) {
    case GetClientServiceFeesActionTypes.GET_CLIENT_SERVICE_FEES_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetClientServiceFeesActionTypes.GET_CLIENT_SERVICE_FEES_SUCCESS:
      return update(state, {
        fees: { $set: action.fees },
        loading: { $set: false },
      });

    case GetClientServiceFeesActionTypes.GET_CLIENT_SERVICE_FEES_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetClientServiceFeesActionTypes.RESET_GET_CLIENT_SERVICE_FEES_STATE:
      return initialGetClientServiceFeesState;

    default:
      return state;
  }
}
