/*Local dependencies */
import { PermissionsType } from '../types';

export enum AccountPermissionsToUserTypes {
  ACCOUNT_PERMISSIONS_TO_USER_REQUEST = 'ACCOUNT_PERMISSIONS_TO_USER_REQUEST',
  ACCOUNT_PERMISSIONS_TO_USER_SUCCESS = 'ACCOUNT_PERMISSIONS_TO_USER_SUCCESS',
  ACCOUNT_PERMISSIONS_TO_USER_ERROR = 'ACCOUNT_PERMISSIONS_TO_USER_ERROR',
  ACCOUNT_PERMISSIONS_TO_USER_RESET_ERROR = 'ACCOUNT_PERMISSIONS_TO_USER_RESET_ERROR',
  ACCOUNT_PERMISSIONS_TO_USER_RESET = 'ACCOUNT_PERMISSIONS_TO_USER_RESET',
  SET_SELECTED_PERMISSIONS = 'SET_SELECTED_PERMISSIONS',
  PERMISSION_PHONE = 'PERMISSION_PHONE',
}

export interface AccountPermissionsToUserInput {
  accountId: string;
  permissions: PermissionsType[];
  phone: string;
}

export interface AccountPermissionsToUserRequest {
  type: AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_REQUEST;
  payload: AccountPermissionsToUserInput;
}

export interface AccountPermissionsToUserSuccess {
  type: AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_SUCCESS;
  statusMessage: string;
}

export interface AccountPermissionsToUserError {
  type: AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_ERROR;
  error: Error;
}

export interface AccountPermissionsToUserResetError {
  type: AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_RESET_ERROR;
}

export interface AccountPermissionsToUserReset {
  type: AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_RESET;
}

export interface PermissionsPhone {
  type: AccountPermissionsToUserTypes.PERMISSION_PHONE;
  phone: string;
}

export interface SelectPermission {
  type: AccountPermissionsToUserTypes.SET_SELECTED_PERMISSIONS;
  permissions: PermissionsType;
}

export type AccountPermissionsToUserActions =
  | AccountPermissionsToUserRequest
  | AccountPermissionsToUserSuccess
  | AccountPermissionsToUserError
  | AccountPermissionsToUserResetError
  | AccountPermissionsToUserReset
  | SelectPermission
  | PermissionsPhone;

export function setAccountPermissionsToUserRequest(
  input: AccountPermissionsToUserInput,
): AccountPermissionsToUserRequest {
  return {
    type: AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_REQUEST,
    payload: input,
  };
}

export function setAccountPermissionsToUserSuccess(statusMessage: string): AccountPermissionsToUserSuccess {
  return {
    type: AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_SUCCESS,
    statusMessage,
  };
}

export function setAccountPermissionsToUserError(error: Error): AccountPermissionsToUserError {
  return {
    type: AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_ERROR,
    error,
  };
}

export function setAccountPermissionsToUserResetError(): AccountPermissionsToUserResetError {
  return {
    type: AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_RESET_ERROR,
  };
}

export function setAccountPermissionsToUserReset(): AccountPermissionsToUserReset {
  return {
    type: AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_RESET,
  };
}

export function setPermissionPhone(phone: string): PermissionsPhone {
  const cleanedValue = phone
    .replace(/^\+996/, '')
    .replace(/\D/g, '')
    .slice(0, 9);

  return {
    type: AccountPermissionsToUserTypes.PERMISSION_PHONE,
    phone: cleanedValue,
  };
}

export function selectedPermission(permissions: PermissionsType): SelectPermission {
  return {
    type: AccountPermissionsToUserTypes.SET_SELECTED_PERMISSIONS,
    permissions,
  };
}
