// Local dependencies
import { Item, ServiceStatus } from '../../../services/types';

export enum UpdateItemActionTypes {
  UPDATE_ITEM = 'UPDATE_ITEM',
  UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS',
  UPDATE_ITEM_ERROR = 'UPDATE_ITEM_ERROR',
  OPEN_CONFIRMATION_POPUP = 'OPEN_CONFIRMATION_POPUP',
  CLOSE_CONFIRMATION_POPUP = 'CLOSE_CONFIRMATION_POPUP',
  CLOSE_SUCCESS_POPUP = 'CLOSE_SUCCESS_POPUP',
  RESET_UPDATE_ITEM_ERROR = 'RESET_UPDATE_ITEM_ERROR',
}

export interface UpdateItem {
  type: UpdateItemActionTypes;
  id: string;
  status: ServiceStatus;
}

export interface UpdateItemSuccess {
  type: UpdateItemActionTypes.UPDATE_ITEM_SUCCESS;
  item: Item;
}

export interface UpdateItemError {
  type: UpdateItemActionTypes.UPDATE_ITEM_ERROR;
  error: Error;
}

export interface CloseConfirmationPopup {
  type: UpdateItemActionTypes.CLOSE_CONFIRMATION_POPUP;
}

export interface OpenConfirmationPopup {
  type: UpdateItemActionTypes.OPEN_CONFIRMATION_POPUP;
}

export interface CloseSuccessPopup {
  type: UpdateItemActionTypes.CLOSE_SUCCESS_POPUP;
}

export interface CloseSuccessModal {
  type: UpdateItemActionTypes.CLOSE_SUCCESS_POPUP;
}

export interface ResetUpdateItemError {
  type: UpdateItemActionTypes.RESET_UPDATE_ITEM_ERROR;
}

export type UpdateItemActions =
  | UpdateItem
  | UpdateItemSuccess
  | UpdateItemError
  | CloseConfirmationPopup
  | OpenConfirmationPopup
  | CloseSuccessModal
  | ResetUpdateItemError;
