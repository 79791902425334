/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Account } from '../../listAccounts/types';
import { GetAccountActions, GetAccountActionTypes } from './types';

interface InitialGetAccountState {
  loading: boolean;
  error?: Error;
  account?: Account;
}

export const initialGetAccountState: InitialGetAccountState = {
  loading: false,
  error: null,
  account: null,
};

export default function getAccountReducer(state = initialGetAccountState, action: GetAccountActions) {
  switch (action.type) {
    case GetAccountActionTypes.GET_ACCOUNT_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case GetAccountActionTypes.GET_ACCOUNT_SUCCESS:
      const { account } = action;

      return update(state, {
        loading: { $set: false },
        account: { $set: account },
      });

    case GetAccountActionTypes.GET_ACCOUNT_ERROR:
      const { error } = action;

      return update(state, {
        loading: { $set: false },
        error: { $set: error },
      });

    case GetAccountActionTypes.RESET_STATE:
      return update(state, {
        $set: initialGetAccountState,
      });

    default:
      return state;
  }
}
