/* Local dependencies */
import { MenuLink } from './StepsComponent';

export enum StepsComponentActionsTypes {
  SET_STEP_COMPONENT = 'SET_STEP_COMPONENT',
}

export type StepsComponentsActions = SetStepsComponent;

export interface SetStepsComponent {
  type: StepsComponentActionsTypes.SET_STEP_COMPONENT;
  payload: MenuLink;
}

export function setStepsComponent(menuItem: MenuLink): SetStepsComponent {
  return {
    type: StepsComponentActionsTypes.SET_STEP_COMPONENT,
    payload: menuItem,
  };
}
