/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { getItemFromLocalStorage } from '../../../components/common/helpers';
import { ListTransactionsAction, ListTransactionsActionTypes, ListTransactionsFilter } from './actions';
import { Transaction } from './types';

export interface TransactionsState {
  currentPage: number;
  filter?: ListTransactionsFilter;
  error?: Error;
  loading?: boolean;
  payments: Transaction[];
  selectedTransactions?: [];
  searchString: string;
  locale?: string;
  size: number;
  total: number;
  startDate?: string;
  endDate?: string;
  totalAmount?: number;
  isDeveloperMode: boolean;
}

const size: number = 20;

let nowDate = new Date();

nowDate.setHours(5, 59, 59, 999);

// In the component we use datetime-local intput this is why we need to add one day
nowDate.setDate(nowDate.getDate() + 1);

// Set by default 7 days ago.
let lastSevenDate = new Date();
lastSevenDate.setDate(lastSevenDate.getDate() - 7);
lastSevenDate.setHours(6, 0, 0, 0);

export const defaultStartDate = lastSevenDate.toISOString().slice(0, 16);
export const defaultEndDate = nowDate.toISOString().slice(0, 16);

const isDeveloperMode = getItemFromLocalStorage('isDeveloperMode');

export const initialTransactionsState: TransactionsState = {
  currentPage: 1,
  filter: {},
  loading: true,
  locale: 'RU',
  payments: [],
  searchString: '',
  selectedTransactions: [],
  startDate: defaultStartDate,
  endDate: defaultEndDate,
  size,
  total: 0,
  totalAmount: 0,
  isDeveloperMode: isDeveloperMode === 'true' ? true : false,
};

export default function transactionsReducer(state = initialTransactionsState, action: ListTransactionsAction) {
  switch (action.type) {
    case ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST:
      const { currentPage, filter, searchString } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: filter },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        endDate: { $set: action.endDate ?? defaultEndDate },
        startDate: { $set: action.startDate ?? defaultStartDate },
        size: { $set: action.size ?? size },
      });

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        payments: { $set: action.payments },
        total: { $set: action.total },
        totalAmount: { $set: action.totalAmount },
      });

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_USER_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        filter: { $set: action.filter },
        currentPage: { $set: action.currentPage },
        searchString: { $set: action.searchString },
        endDate: { $set: action.endDate },
        startDate: { $set: action.startDate },
      });

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListTransactionsActionTypes.SET_TRANSACTIONS_START_DATE:
      return update(state, {
        startDate: { $set: action.startDate },
      });

    case ListTransactionsActionTypes.SET_TRANSACTIONS_END_DATE:
      const { endDate } = action;

      return update(state, {
        endDate: { $set: endDate },
      });

    case ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR_RESET:
      return update(state, {
        error: { $set: null },
      });

    case ListTransactionsActionTypes.TOGGLE_TABLE_MODE:
      return update(state, {
        isDeveloperMode: { $set: action.isDeveloperMode ?? !state.isDeveloperMode },
      });

    default:
      return state;
  }
}
