// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { SelectCommission } from '../../../../components/fees/types';
import { Terminal } from '../types';
import { ListTerminalsAction, ListTerminalsActionTypes, ListTerminalsFilter } from './actions';

export interface TerminalsState {
  currentPage: number;
  error?: Error;
  entityType: SelectCommission;
  loading: boolean;
  terminals: Terminal[];
  selectedTerminals: Terminal[];
  selectedTerminal?: Terminal;
  searchString: string;
  filter?: ListTerminalsFilter;
  size: number;
  total: number;
}

const size: number = 10;

export const initialTerminalsState: TerminalsState = {
  currentPage: 1,
  filter: {},
  entityType: SelectCommission.SELECT_COMISSION,
  loading: false,
  terminals: [],
  selectedTerminals: [],
  selectedTerminal: null,
  searchString: '',
  size,
  total: 0,
};

export default function terminalsReducer(state = initialTerminalsState, action: ListTerminalsAction) {
  switch (action.type) {
    case ListTerminalsActionTypes.LIST_TERMINALS_REQUEST:
      const { currentPage, searchString, filter } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        currentPage: { $set: currentPage },
        searchString: { $set: searchString },
        filter: { $set: filter },
        selectedTerminal: { $set: null },
        selectedTerminals: { $set: [] },
      });

    case ListTerminalsActionTypes.LIST_TERMINALS_SUCCESS:
      return update(state, {
        loading: { $set: false },
        terminals: { $set: action.terminals },
        total: { $set: action.total },
      });

    case ListTerminalsActionTypes.LIST_TERMINALS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case ListTerminalsActionTypes.SELECT_TERMINAL:
      const selectedTerminals = state.selectedTerminals.filter((item) => {
        return item.id !== action.terminal.id;
      });

      if (selectedTerminals.length < state.selectedTerminals.length) {
        return update(state, {
          selectedTerminals: { $set: selectedTerminals },
        });
      }

      return update(state, {
        selectedTerminals: { $push: [action.terminal] },
      });

    case ListTerminalsActionTypes.SELECT_TERMINAL_SINGLE:
      return update(state, {
        selectedTerminal: { $set: action.terminal },
      });

    case ListTerminalsActionTypes.RESET_SELECTED_TERMINALS:
      return update(state, {
        selectedTerminals: { $set: initialTerminalsState.selectedTerminals },
        selectedTerminal: { $set: initialTerminalsState.selectedTerminal },
      });

    case ListTerminalsActionTypes.SELECT_ENTITY_TYPE:
      return update(state, {
        entityType: { $set: action.entityType },
      });

    case ListTerminalsActionTypes.RESET_LIST_TERMINALS:
      return update(state, { $set: initialTerminalsState });

    case ListTerminalsActionTypes.RESET_LIST_TERMINALS_ERROR:
      return update(state, { $unset: ['error'] });

    default:
      return state;
  }
}
