/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { sourceAccountIdWithName, TopUpAccountBalance, TopUpBalanceAction, TopUpBalanceActionTypes } from './actions';
import { validateTopUpBalanceFields } from './validateTopUpBalanceFields';

export interface TopUpBalanceState {
  amountError?: Error;
  error?: Error;
  isTopUpBalanceOperation?: boolean;
  isTopUpBalanceSuccess?: boolean;
  loading?: boolean;
  shouldShowConfirmationPopup?: boolean;
  topUpBalance?: TopUpAccountBalance;
  sourceAccountIdWithName?: sourceAccountIdWithName;
}

const initialTopUPBalanceState: TopUpBalanceState = {
  isTopUpBalanceSuccess: false,
  topUpBalance: {
    amount: null,
    destinationAccountId: '',
    sourceAccountId: '',
    transactionId: null,
  },
  shouldShowConfirmationPopup: false,
};

export default function topUpBalanceReducer(state = initialTopUPBalanceState, action: TopUpBalanceAction) {
  switch (action.type) {
    case TopUpBalanceActionTypes.TOP_UP_BALANCE_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case TopUpBalanceActionTypes.TOP_UP_BALANCE_SUCCESS:
      const { amount } = action;

      return update(state, {
        loading: { $set: false },
        isTopUpBalanceSuccess: { $set: true },
        topUpBalance: {
          $set: {
            amount,
          },
        },
        shouldShowConfirmationPopup: { $set: false },
      });

    case TopUpBalanceActionTypes.TOP_UP_BALANCE_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
        shouldShowConfirmationPopup: { $set: false },
      });

    case TopUpBalanceActionTypes.UPDATE_TOP_UP_BALANCE_FIELDS:
      return validateTopUpBalanceFields(state, action.updates);

    case TopUpBalanceActionTypes.UPDATE_SOURCE_ACCOUNT_WITH_NAME_FIELDS:
      return update(state, {
        sourceAccountIdWithName: { $set: action.sourceAccountIdWithName },
      });

    case TopUpBalanceActionTypes.IS_TOP_UP_BALANCE:
      return update(state, {
        isTopUpBalanceOperation: { $set: true },
        loading: { $set: false },
      });

    case TopUpBalanceActionTypes.RESET_TOP_UP_BALANCE_STATE:
      return update(state, { $set: initialTopUPBalanceState });

    case TopUpBalanceActionTypes.RESET_ERROR:
      return update(state, { $unset: ['error'], shouldShowConfirmationPopup: { $set: true } });

    case TopUpBalanceActionTypes.SHOW_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: true },
      });

    case TopUpBalanceActionTypes.HIDE_CONFIRMATION_POPUP:
      return update(state, {
        shouldShowConfirmationPopup: { $set: false },
      });

    default:
      return state;
  }
}
