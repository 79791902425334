/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { ServiceFees } from '../../../../../components/services/types';
import { getClient } from '../../../../../clients/averspay';
import {
  GetClientServiceFeesAction,
  GetClientServiceFeesActionTypes,
  getClientServiceFeesSuccess,
  getClientServiceFeesError,
  GetClientServiceFeesRequest,
} from './actions';
import { getClientServiceFeesQuery } from './query';

export default function getClientServiceFeesEpic(action$) {
  return action$.pipe(
    filter(
      (action: GetClientServiceFeesAction) =>
        action.type === GetClientServiceFeesActionTypes.GET_CLIENT_SERVICE_FEES_REQUEST,
    ),
    switchMap((action: GetClientServiceFeesRequest) =>
      getClientServiceFees(action).then(getClientServiceFeesSuccess).catch(getClientServiceFeesError),
    ),
  );
}

export async function getClientServiceFees(action: GetClientServiceFeesRequest): Promise<ServiceFees> {
  const graphQLClient = await getClient();
  const { serviceId } = action;

  const {
    data: { getClientServiceFees },
  } = await graphQLClient.query({
    query: getClientServiceFeesQuery,
    variables: { input: { serviceId } },
  });

  return getClientServiceFees;
}
