/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Transaction } from '../../../../components/transactions/listTransactions/types';
import { MakePaymentAction, MakePaymentActionTypes } from './actions';

export interface MakePaymentState {
  error?: Error;
  loading: boolean;
  paymentData?: Partial<Transaction>;
}

export const initialMakePaymentState: MakePaymentState = {
  loading: false,
};

export default function makePaymentReducer(
  state = initialMakePaymentState,
  action: MakePaymentAction,
): MakePaymentState {
  switch (action.type) {
    case MakePaymentActionTypes.MAKE_PAYMENT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case MakePaymentActionTypes.MAKE_PAYMENT_SUCCESS:
      return update(state, {
        paymentData: { $set: action.paymentData },
        loading: { $set: false },
      });

    case MakePaymentActionTypes.MAKE_PAYMENT_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case MakePaymentActionTypes.RESET_MAKE_PAYMENT_STATE:
      return initialMakePaymentState;

    case MakePaymentActionTypes.RESET_MAKE_PAYMENT_ERROR:
      return update(state, {
        error: { $set: null },
        loading: { $set: false },
      });

    default:
      return state;
  }
}
