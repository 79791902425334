import { gql } from '@apollo/client';

export const listFilterOptionsQuery = gql`
  query listPaymentsFilterOptions($input: ListPaymentsFilterOptionsInput!) {
    listPaymentsFilterOptions(input: $input) {
      total
      results {
        key
      }
    }
  }
`;
