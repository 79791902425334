// External dependencies
import { gql } from '@apollo/client';

export const updateItemMutation = gql`
  mutation updateItem($input: UpdateItemInput!) {
    updateItem(input: $input) {
      id
    }
  }
`;
