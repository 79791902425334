// Local dependencies
import { Account } from '../../listAccounts/types';
import {
  GetAccountActionTypes,
  GetAccountError,
  GetAccountRequest,
  GetAccountSuccess,
  ResetGetAccountState,
} from './types';

export function getAccount(id: string): GetAccountRequest {
  return {
    type: GetAccountActionTypes.GET_ACCOUNT_REQUEST,
    id,
  };
}

export function getAccountSuccess(account: Account): GetAccountSuccess {
  return {
    type: GetAccountActionTypes.GET_ACCOUNT_SUCCESS,
    account,
  };
}

export function getAccountError(error: Error): GetAccountError {
  return {
    type: GetAccountActionTypes.GET_ACCOUNT_ERROR,
    error,
  };
}

export function resetGetAccountState(): ResetGetAccountState {
  return {
    type: GetAccountActionTypes.RESET_STATE,
  };
}
