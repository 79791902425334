/* External dependencies */
import gql from 'graphql-tag';

export const getMakePaymentMutation = gql`
  mutation makePayment($input: MakePaymentInput!) {
    makePayment(input: $input) {
      status
    }
  }
`;
