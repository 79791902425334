/* External dependencies */
import gql from 'graphql-tag';

export const setAccountPermissionsToUserMutation = gql`
  mutation setAccountPermissionsToUser($input: SetAccountPermissionsToUserInput!) {
    setAccountPermissionsToUser(input: $input) {
      statusMessage
    }
  }
`;
