// External dependencies
import { debounceTime, filter, switchMap } from 'rxjs';

// Local dependencies
import { getClient } from '../../../../clients/averspay';
import { getItemError, getItemSuccess } from './actions';
import { getItemQuery } from './queries';
import { GetItem, GetItemAction, GetItemActionTypes, GetItemSuccess } from './types';

export default function getItemEpic(action$) {
  return action$.pipe(
    filter((action: GetItemAction) => action.type === GetItemActionTypes.GET_ITEM_REQUEST),
    switchMap((action: GetItem) => getItem(action).catch((error) => getItemError(error))),
  );
}

async function getItem(params: GetItem): Promise<GetItemSuccess> {
  const { id } = params;
  const graphQLClient = await getClient();

  const {
    data: { getItem },
  } = await graphQLClient.query({
    query: getItemQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getItemSuccess(getItem);
}
