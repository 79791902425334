/* Local dependencies */
import { ServiceFees } from '../../../../../components/services/types';

export enum GetClientServiceFeesActionTypes {
  GET_CLIENT_SERVICE_FEES_REQUEST = 'GET_CLIENT_SERVICE_FEES_REQUEST',
  GET_CLIENT_SERVICE_FEES_SUCCESS = 'GET_CLIENT_SERVICE_FEES_SUCCESS',
  GET_CLIENT_SERVICE_FEES_ERROR = 'GET_CLIENT_SERVICE_FEES_ERROR',
  RESET_GET_CLIENT_SERVICE_FEES_STATE = 'RESET_GET_CLIENT_SERVICE_FEES_STATE',
}

export interface GetClientServiceFeesRequest {
  type: GetClientServiceFeesActionTypes.GET_CLIENT_SERVICE_FEES_REQUEST;
  serviceId: string;
}

export interface GetClientServiceFeesSuccess {
  type: GetClientServiceFeesActionTypes.GET_CLIENT_SERVICE_FEES_SUCCESS;
  fees: ServiceFees;
}

export interface GetClientServiceFeesError {
  type: GetClientServiceFeesActionTypes.GET_CLIENT_SERVICE_FEES_ERROR;
  error: Error;
}

export interface ResetGetClientServiceFeesState {
  type: GetClientServiceFeesActionTypes.RESET_GET_CLIENT_SERVICE_FEES_STATE;
}

export type GetClientServiceFeesAction =
  | GetClientServiceFeesRequest
  | GetClientServiceFeesSuccess
  | GetClientServiceFeesError
  | ResetGetClientServiceFeesState;

export function getClientServiceFeesRequest(serviceId: string): GetClientServiceFeesRequest {
  return {
    type: GetClientServiceFeesActionTypes.GET_CLIENT_SERVICE_FEES_REQUEST,
    serviceId,
  };
}

export function getClientServiceFeesSuccess(fees: ServiceFees): GetClientServiceFeesSuccess {
  return {
    type: GetClientServiceFeesActionTypes.GET_CLIENT_SERVICE_FEES_SUCCESS,
    fees,
  };
}

export function getClientServiceFeesError(error: Error): GetClientServiceFeesError {
  return {
    type: GetClientServiceFeesActionTypes.GET_CLIENT_SERVICE_FEES_ERROR,
    error,
  };
}

export function resetGetClientServiceFeesState(): ResetGetClientServiceFeesState {
  return {
    type: GetClientServiceFeesActionTypes.RESET_GET_CLIENT_SERVICE_FEES_STATE,
  };
}
