/* Local dependencies */
import { User } from '../../../../components/users/userTypes';

export enum CreateUserActionType {
  CREATE_USER_REQUEST = 'CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE = 'CREATE_USER_FAILURE',
  RESET_CREATE_USER = 'RESET_CREATE_USER',
  UPDATE_ORGANIZATION_FIELD = 'UPDATE_ORGANIZATION_FIELD',
  UPDATE_ORGANIZATION_VALIDITY = 'UPDATE_ORGANIZATION_VALIDITY',
  CONFIRMATION_MODAL = 'CONFIRMATION_MODAL',
  RESET_CREATE_USER_ERROR = 'RESET_CREATE_USER_ERROR',
}

export interface CreateUserOrganization {
  firstName: string;
  lastName: string;
  phone: string;
}

export interface CreateUserRequestAction {
  type: CreateUserActionType.CREATE_USER_REQUEST;
  payload: CreateUserOrganization;
}

export interface CreateUserSuccessAction {
  type: CreateUserActionType.CREATE_USER_SUCCESS;
  payload: User;
}

export interface CreateUserFailureAction {
  type: CreateUserActionType.CREATE_USER_FAILURE;
  error: Error;
}

export interface ResetCreateUserAction {
  type: CreateUserActionType.RESET_CREATE_USER;
}

export interface UpdateOrganizationFieldAction {
  type: CreateUserActionType.UPDATE_ORGANIZATION_FIELD;
  field: string;
  value: string;
}

export interface UpdateOrganizationValidityAction {
  type: CreateUserActionType.UPDATE_ORGANIZATION_VALIDITY;
  isValid: boolean;
}

export interface ConfirmationModalAction {
  type: CreateUserActionType.CONFIRMATION_MODAL;
  isOpen: boolean;
}

export interface ResetCreateUserErrorAction {
  type: CreateUserActionType.RESET_CREATE_USER_ERROR;
}

export type CreateUserActions =
  | CreateUserRequestAction
  | CreateUserSuccessAction
  | CreateUserFailureAction
  | ResetCreateUserAction
  | UpdateOrganizationFieldAction
  | UpdateOrganizationValidityAction
  | ConfirmationModalAction
  | ResetCreateUserErrorAction;

export const createUser = (payload: CreateUserOrganization): CreateUserRequestAction => ({
  type: CreateUserActionType.CREATE_USER_REQUEST,
  payload,
});

export const createUserSuccess = (payload: User): CreateUserSuccessAction => ({
  type: CreateUserActionType.CREATE_USER_SUCCESS,
  payload,
});

export const createUserFailure = (error: Error): CreateUserFailureAction => ({
  type: CreateUserActionType.CREATE_USER_FAILURE,
  error,
});

export const resetCreateUser = (): ResetCreateUserAction => ({
  type: CreateUserActionType.RESET_CREATE_USER,
});

export const createUserUpdateOrganizationField = (field: string, value: string): UpdateOrganizationFieldAction => ({
  type: CreateUserActionType.UPDATE_ORGANIZATION_FIELD,
  field,
  value,
});

export const updateOrganizationValidity = (isValid: boolean): UpdateOrganizationValidityAction => ({
  type: CreateUserActionType.UPDATE_ORGANIZATION_VALIDITY,
  isValid,
});

export const confirmationModal = (isOpen: boolean): ConfirmationModalAction => ({
  type: CreateUserActionType.CONFIRMATION_MODAL,
  isOpen,
});

export const resetCreateUserError = (): ResetCreateUserErrorAction => ({
  type: CreateUserActionType.RESET_CREATE_USER_ERROR,
});
