/* External dependencies */
import { ApolloClient } from '@apollo/client';
import * as AWSCognito from 'amazon-cognito-identity-js';
import { NormalizedCacheObject } from 'apollo-cache-inmemory';
import { navigate } from 'gatsby';
import update from 'immutability-helper';

/* Local dependencies */
import { cleanUpSession, setupClient } from '../../../clients/averspay';
import { getSessionUser } from '../../../clients/cognito';
import { updateOneSignalExternalUserId } from '../../common/oneSIgnal';
import { LoginAction, LoginActionTypes, SessionUser } from './actions';

export type LoginState = {
  // `client` is used by the ApolloWrapper to pass down to the `ApolloProvider`.
  client?: ApolloClient<NormalizedCacheObject>;
  currentUser?: any;
  currentUserSession?: any;
  error?: Error;
  initializing?: boolean;
  loading?: boolean;
  username?: string;
  phoneNumber?: {
    formatted: string;
    onlyNumbers: string;
  };
  verificationCode?: string;
};

const initialLoginState: LoginState = {
  initializing: true,
};

export default function loginReducer(state = initialLoginState, action: LoginAction) {
  switch (action.type) {
    case LoginActionTypes.INIT_CLIENT_SUCCEEDED:
      const { session } = action;

      const idToken = session.getIdToken().getJwtToken();
      const idTokenObj = new AWSCognito.CognitoIdToken({ IdToken: idToken });
      const currentUser = getSessionUser(idTokenObj) as SessionUser;
      const client = setupClient(session);

      updateOneSignalExternalUserId(currentUser.sub);

      return {
        client: client,
        currentUser: currentUser,
        initializing: false,
        loading: false,
      };

    case LoginActionTypes.INIT_CLIENT_FAILED:
      updateOneSignalExternalUserId();

      return update(state, {
        initializing: { $set: false },
      });

    case LoginActionTypes.INIT_ANANONYMOUS_CLIENT:
      return update(state, {
        client: { $set: action.client },
      });

    case LoginActionTypes.LOGIN_REQUEST:
      const { username } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        username: { $set: username },
      });

    case LoginActionTypes.LOGIN_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case LoginActionTypes.LOGOUT_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case LoginActionTypes.LOGOUT_SUCCESS:
      cleanUpSession();

      navigate('/');

      return update(state, {
        loading: { $set: false },
        $unset: ['client'],
      });

    case LoginActionTypes.LOGOUT_ERROR:
      const { error } = action;

      return update(state, {
        error: { $set: error },
        loading: { $set: false },
      });

    case LoginActionTypes.SEND_VERIFICATION_CODE_REQUEST:
      return update(state, {
        verificationCode: { $set: action.verificationCode },
        loading: { $set: true },
      });

    case LoginActionTypes.SET_USER_SESSION:
      navigate('/login/verify-code');

      return update(state, {
        currentUserSession: { $set: action.currentUserSession },
        loading: { $set: false },
      });

    case LoginActionTypes.SET_PHONE_NUMBER:
      return update(state, {
        phoneNumber: { $set: action.phoneNumber },
      });

    default:
      return state;
  }
}
