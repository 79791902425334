/* Local dependencies */
import { ServiceRecipientInput, ServiceRecipientResponse } from '../types';

export enum CheckRecipientActionTypes {
  CHECK_RECIPIENT_REQUEST = 'CHECK_RECIPIENT_REQUEST',
  CHECK_RECIPIENT_SUCCESS = 'CHECK_RECIPIENT_SUCCESS',
  CHECK_RECIPIENT_ERROR = 'CHECK_RECIPIENT_ERROR',
  RESET_CHECK_RECIPIENT_STATE = 'RESET_CHECK_RECIPIENT_STATE',
  RESET_CHECK_RECIPIENT_ERROR = 'RESET_CHECK_RECIPIENT_ERROR',
}

export interface CheckRecipientRequest {
  type: CheckRecipientActionTypes.CHECK_RECIPIENT_REQUEST;
  payload: ServiceRecipientInput;
}

export interface CheckRecipientSuccess {
  type: CheckRecipientActionTypes.CHECK_RECIPIENT_SUCCESS;
  recipientData: ServiceRecipientResponse;
}

export interface CheckRecipientError {
  type: CheckRecipientActionTypes.CHECK_RECIPIENT_ERROR;
  error: Error;
}

export interface ResetCheckRecipientState {
  type: CheckRecipientActionTypes.RESET_CHECK_RECIPIENT_STATE;
}

export interface ResetCheckRecipientError {
  type: CheckRecipientActionTypes.RESET_CHECK_RECIPIENT_ERROR;
}

export type CheckRecipientAction =
  | CheckRecipientRequest
  | CheckRecipientSuccess
  | CheckRecipientError
  | ResetCheckRecipientState
  | ResetCheckRecipientError;

export function checkRecipientRequest(payload: ServiceRecipientInput): CheckRecipientRequest {
  return {
    type: CheckRecipientActionTypes.CHECK_RECIPIENT_REQUEST,
    payload,
  };
}

export function checkRecipientSuccess(recipientData: ServiceRecipientResponse): CheckRecipientSuccess {
  return {
    type: CheckRecipientActionTypes.CHECK_RECIPIENT_SUCCESS,
    recipientData,
  };
}

export function checkRecipientError(error: Error): CheckRecipientError {
  return {
    type: CheckRecipientActionTypes.CHECK_RECIPIENT_ERROR,
    error,
  };
}

export function resetCheckRecipientState(): ResetCheckRecipientState {
  return {
    type: CheckRecipientActionTypes.RESET_CHECK_RECIPIENT_STATE,
  };
}

export function resetCheckRecipientError(): ResetCheckRecipientError {
  return {
    type: CheckRecipientActionTypes.RESET_CHECK_RECIPIENT_ERROR,
  };
}
