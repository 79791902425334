/* External dependencies */
import { Epic, ofType } from 'redux-observable';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { User } from '../../../../components/users/userTypes';
import {
  CreateUserActionType,
  createUserFailure,
  CreateUserOrganization,
  CreateUserRequestAction,
  createUserSuccess,
} from './action';
import { createUserWithCorporateAccountMutation } from './mutation';

export const createUserEpic: Epic = (action$: Observable<any>): Observable<any> =>
  action$.pipe(
    ofType(CreateUserActionType.CREATE_USER_REQUEST),
    switchMap((action: CreateUserRequestAction) =>
      createUser(action.payload).then(createUserSuccess).catch(createUserFailure),
    ),
  );

export async function createUser(input: CreateUserOrganization): Promise<User> {
  const graphQLClient = await getClient();

  const {
    data: { createUserWithCorporateAccount },
  } = await graphQLClient.mutate({
    mutation: createUserWithCorporateAccountMutation,
    variables: {
      input,
    },
  });

  return createUserWithCorporateAccount;
}
