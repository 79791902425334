/* Local dependencies */
import { ClientServiceLimits } from '../types';

export enum GetClientServiceLimitsActionTypes {
  GET_CLIENT_SERVICE_LIMITS_REQUEST = 'GET_CLIENT_SERVICE_LIMITS_REQUEST',
  GET_CLIENT_SERVICE_LIMITS_SUCCESS = 'GET_CLIENT_SERVICE_LIMITS_SUCCESS',
  GET_CLIENT_SERVICE_LIMITS_ERROR = 'GET_CLIENT_SERVICE_LIMITS_ERROR',
  RESET_GET_CLIENT_SERVICE_LIMITS_STATE = 'RESET_GET_CLIENT_SERVICE_LIMITS_STATE',
}

export interface GetClientServiceLimitsRequest {
  type: GetClientServiceLimitsActionTypes.GET_CLIENT_SERVICE_LIMITS_REQUEST;
  serviceId: string;
}

export interface GetClientServiceLimitsSuccess {
  type: GetClientServiceLimitsActionTypes.GET_CLIENT_SERVICE_LIMITS_SUCCESS;
  limits: ClientServiceLimits; // Adjust type as needed
}

export interface GetClientServiceLimitsError {
  type: GetClientServiceLimitsActionTypes.GET_CLIENT_SERVICE_LIMITS_ERROR;
  error: Error;
}

export interface ResetGetClientServiceLimitsState {
  type: GetClientServiceLimitsActionTypes.RESET_GET_CLIENT_SERVICE_LIMITS_STATE;
}

export type GetClientServiceLimitsAction =
  | GetClientServiceLimitsRequest
  | GetClientServiceLimitsSuccess
  | GetClientServiceLimitsError
  | ResetGetClientServiceLimitsState;

export function getClientServiceLimitsRequest(serviceId: string): GetClientServiceLimitsRequest {
  return {
    type: GetClientServiceLimitsActionTypes.GET_CLIENT_SERVICE_LIMITS_REQUEST,
    serviceId,
  };
}

export function getClientServiceLimitsSuccess(limits: ClientServiceLimits): GetClientServiceLimitsSuccess {
  return {
    type: GetClientServiceLimitsActionTypes.GET_CLIENT_SERVICE_LIMITS_SUCCESS,
    limits,
  };
}

export function getClientServiceLimitsError(error: Error): GetClientServiceLimitsError {
  return {
    type: GetClientServiceLimitsActionTypes.GET_CLIENT_SERVICE_LIMITS_ERROR,
    error,
  };
}

export function resetGetClientServiceLimitsState(): ResetGetClientServiceLimitsState {
  return {
    type: GetClientServiceLimitsActionTypes.RESET_GET_CLIENT_SERVICE_LIMITS_STATE,
  };
}
