/* Local dependencies */
import { ServiceDevice } from '../../components/devices/terminals/types';
import { Account, AccountForServiceEndpoint, AccountInput } from '../accounts/listAccounts/types';

export interface CreateService {
  devices?: ServiceDevice[];
  country: string;
  currency?: Currency;
  fees?: ServiceFees;
  limit?: Limit;
  logo?: Logo;
  maxAmount?: number;
  minAmount?: number;
  name_en: string;
  name_ky: string;
  name_ru: string;
  parentId: string;
  requiredFields: [RequiredServiceFieldInput];
  serviceEndpoint?: ServiceEndpointInput;
  slug: string;
  supportsDecimals?: boolean;
}

export interface UpdateServiceFields {
  amount?: number;
  currency?: Currency;
  devices?: ServiceDevice[];
  dynamicFeeStepsCheck?: string;
  fees?: ServiceFees;
  flatFeePercent?: number;
  id: string;
  limit?: Limit;
  logo?: Logo;
  maxAmount?: number;
  minAmount?: number;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  parentId?: string;
  parents?: [Service];
  popularityScore?: number;
  requiredFields?: [RequiredServiceFieldInput];
  serviceEndpoint?: ServiceEndpointInput;
  slug?: string;
  status?: ServiceStatus;
  statusMessage?: string;
  supportsDecimals?: boolean;
}

export interface UpdateCategoryFields {
  logo?: Logo;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  parentId?: string;
  popularityScore?: number;
  slug?: string;
  status?: ServiceStatus;
  statusMessage?: string;
}

export interface CreateCategory {
  logo?: Logo;
  name_en: string;
  name_ky: string;
  name_ru: string;
  parentId: string;
  slug: string;
}

export interface CreateCategoryFields {
  logo?: Logo;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  slug?: string;
}

export interface UpdateCategory {
  logo?: Logo;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  parentId?: string;
  parents?: [Service];
  popularityScore: number;
  slug?: string;
  status?: ServiceStatus;
  statusMessage?: string;
}

export enum ServiceStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  INACTIVE = 'INACTIVE',
}

export enum ServicesStatusMessage {
  OTHER = 'OTHER',
  SUPPLIER_FAILURE = 'SUPPLIER_FAILURE',
  TECHNICAL_WORKS = 'TECHNICAL_WORKS',
}

export type ServiceStasuses = [ServiceStatus];

export type ServiceFeeStep = {
  dynamicFeeType?: any;
  fee: ServiceFees;
  from: Money;
};

export type ServiceFees = {
  dynamicFeeSteps?: ServiceFeeStep[];
  flatFee?: Money;
  flatFeePercent?: number;
};

export type Logo = {
  url?: string;
};

export enum Currency {
  KGS = 'KGS',
  RUB = 'RUB',
  USD = 'USD',
}

export const SERVICES = {
  AVERSPAY: {
    id: 'averspay',
  },
};

export type Money = {
  amount?: number;
  currency?: Currency;
};

export type ServiceAuthentication = {
  username?: string;
  password?: string;
};

export interface Service {
  country?: string;
  currency?: Currency;
  devices: ServiceDevice[];
  fees?: ServiceFees;
  id: string;
  inputMask?: string;
  lastDisabledDate?: number;
  lastEnabledDate?: number;
  limit?: Limit;
  logo?: Logo;
  maxAmount?: number;
  minAmount?: number;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  parentId: string;
  parents?: [Service];
  registrationDate: number;
  requiredFields: [RequiredServiceFieldInput];
  server?: string;
  serviceEndpoint: ServiceEndpoint;
  slug?: string;
  status: ServiceStatus;
  statusMessage?: string;
  supportsDecimals?: boolean;
  typename: string;
}

export interface Limit {
  id: string;
}

export type Services = Service[];

export interface Category {
  id: string;
  lastDisabledDate?: number;
  lastEnabledDate?: number;
  logo?: Logo;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  parentId: string;
  parents?: [Service];
  registrationDate?: number;
  slug?: string;
  status?: ServiceStatus;
  statusMessage?: string;
  typename?: ServiceTypename;
}

export type ServiceConnection = {
  auth?: ServiceAuthentication;
  format?: string;
  ip?: string;
  method?: string;
  port?: string;
  protocol?: string;
};

export type RequiredServiceFieldInput = {
  checkedBox?: boolean;
  excludeFromPayload?: boolean;
  fieldId?: string;
  fieldIdError?: Error;
  hasSpaceAfterPrefix?: boolean;
  index?: any;
  inputMask?: string;
  keyboardChek?: boolean;
  keyboardType: KeyboardType;
  keyboardTypeError?: Error;
  label_en?: string;
  label_ky?: string;
  label_ru?: string;
  labelEnError?: Error;
  labelKyError?: Error;
  labelRuError?: Error;
  maxLength?: any;
  minLength?: any;
  orderNumber?: number;
  prefix?: string;
  value?: any;
  valueError?: Error;
  isHidden: boolean | null;
};

export interface ServiceEndpoint {
  account: AccountForServiceEndpoint;
  auth?: ServiceAuthentication;
  format: string;
  id: string;
  ip: string;
  method: string;
  name: string;
  port?: number;
  protocol: string;
}

export interface ServiceEndpointInput {
  id: string;
}
export interface CreateServiceEndpointInput {
  account: AccountInput;
  auth?: ServiceAuthentication;
  format: string;
  ip: string;
  method: string;
  name: string;
  port?: number;
  protocol: string;
}

export interface CreateServiceEndpointFields {
  accountId: string;
  format: string;
  ip: string;
  method: string;
  name: string;
  password: string;
  port?: number;
  protocol: string;
  username: string;
}

export interface ListServiceEndpointsInput {
  from?: number;
  query?: string;
  size?: number;
}

export interface ListServiceEndpoints {
  total: number;
  serviceEndpoints: [ServiceEndpoint];
}

export enum FeesTypes {
  DYNAMIC_FEE_STEPS = 'dynamicFeeSteps',
  FLAT_FEE = 'flatFee',
  FLAT_FEE_PERCENT = 'flatFeePercent',
}

export enum FormatTypes {
  JSON = 'JSON',
  XML = 'XML',
}

export enum DynamicFeeType {
  AMOUNT = 'amount',
  PERCENT = 'percent',
}

export enum ServiceTypename {
  CATEGORY = 'Category',
  SERVICE = 'Service',
}

export enum KeyboardType {
  EMAIL = 'EMAIL',
  MONEY = 'MONEY',
  NUMBER = 'NUMBER',
  PHONE = 'PHONE',
  PHONE_USER = 'PHONE_USER',
  TEXT = 'TEXT',
  TEXT_DEVICE_ID = 'TEXT_DEVICE_ID',
}

export enum MethodTypes {
  POST = 'POST',
  GET = 'GET',
  PUT = 'PUT',
  DELETE = 'DELETE',
}

export interface GetService {
  id: string;
}

export const MIN_NUMBER_FOR_COMMISSION = -1;

export interface DeleteServiceInput {
  id: string;
}

export interface UpdateServiceValidateFields {
  amount?: number;
  currency?: Currency;
  dynamicFeeStepsCheck?: string;
  fees?: ServiceFees;
  flatFeePercent?: number;
  id?: string;
  limit?: Limit;
  logo?: Logo;
  maxAmount?: number;
  minAmount?: number;
  name_en?: string;
  name_ky?: string;
  name_ru?: string;
  parentId?: UpdateServiceParentIdInput;
  parents?: [Service];
  popularityScore?: number;
  requiredFields?: [RequiredServiceFieldInput];
  serviceEndpoint?: ServiceEndpointInput;
  slug?: string;
  status?: ServiceStatus;
  statusMessage?: string;
  supportsDecimals?: boolean;
}

export interface UpdateServiceParentIdInput {
  name: string;
  value: string;
  label: string;
}

export interface QRCode {
  image: string;
  url: string;
}

export interface MediaFile {
  url: string;
  id: string;
}

export interface Item {
  account: Account;
  callbackUrl?: string;
  country?: string;
  endDate?: number;
  maxAvailableQuantity?: number;
  startDate?: number;
  name_en: string;
  name_ky: string;
  name_ru: string;
  fixedAmount?: number;
  qrCode: {
    image: string;
    url: string;
  };
  id: string;
  logo: MediaFile;
  status: ServiceStatus;
}
