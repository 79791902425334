/* External dependencies */
import gql from 'graphql-tag';

export const createAccountForAdminPanelMutation = gql`
  mutation createAccountForAdminPanel($input: CreateAccountInput!) {
    createAccountForAdminPanel(input: $input) {
      id
    }
  }
`;

export const createAccountMutation = gql`
  mutation createAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      id
    }
  }
`;
