/* External dependencies */
import gql from 'graphql-tag';

export const getAccountQuery = gql`
  query getAccount($input: GetAccountInput!) {
    getAccount(input: $input) {
      id
      name
      type
      balance {
        amount
        currency
      }
      status {
        statusType
      }
      user {
        id
        firstName
        lastName
      }
    }
  }
`;
