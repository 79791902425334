/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { CreateItemAction, CreateItemActions, CreateItemActionTypes, createItemError, createItemSuccess } from './action';
import { createItemMutation } from './queries';

export default function createItemEpic(action$) {
  return action$.pipe(
    filter((action: CreateItemActions) => action.type === CreateItemActionTypes.CREATE_ITEM_REQUEST),
    switchMap((action: CreateItemAction) =>
      createItem(action)
        .then(createItemSuccess)
        .catch((error: Error) => createItemError(error)),
    ),
  );
}

export async function createItem(action: CreateItemAction) {
  const graphQLClient = await getClient();

  const {
    data: { createItem },
  } = await graphQLClient.mutate({
    mutation: createItemMutation,
    variables: {
      input: action.payload,
    },
  });

  return createItem;
}
