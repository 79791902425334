/* External dependencies */
import gql from 'graphql-tag';

export const getClientServiceFeesQuery = gql`
  query getClientServiceFees($input: GetClientServiceFeesInput!) {
    getClientServiceFees(input: $input) {
      flatFee {
        amount
      }
      flatFeePercent
      dynamicFeeSteps {
        from {
          amount
        }
        fee {
          flatFee {
            amount
          }
          flatFeePercent
        }
      }
    }
  }
`;
