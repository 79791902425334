// External dependencies
import { catchError, debounceTime, filter, from, map, mergeMap, of, reduce, switchMap } from 'rxjs';

// Local dependencies
import { getClient } from '../../clients/averspay';
import { listFilterOptionsFailed, listFilterOptionsSuccess } from './actions';
import { listFilterOptionsQuery } from './queries';
import {
  FilterOptionsActionTypes,
  ListFilterOptionsAction,
  ListFilterOptionsActionRequest,
  ListFilterOptionsResponse,
} from './types';

// Helper functions to make the code more readable and maintainable
const extractKeys = (response: ListFilterOptionsResponse) =>
  response.results.map((result) => result.key).filter(Boolean);

const fetchFilterOptions = async (params: ListFilterOptionsActionRequest, currentUser) => {
  const { key } = params;
  const graphQLClient = await getClient();
  // const userRole = getUserRole(currentUser);

  const filter = {
    userId: '9b2dbb2a-7374-4116-ad2f-71437c474a7d',
    ...(key && { key }),
  };

  filter.userId = '0b3e275a-5bda-4ebc-9047-93bd4dd39982';

  const {
    data: { listPaymentsFilterOptions },
  } = await graphQLClient.query<{
    listPaymentsFilterOptions: ListFilterOptionsResponse;
  }>({
    query: listFilterOptionsQuery,
    variables: {
      input: {
        filter,
      },
    },
  });

  return listPaymentsFilterOptions;
};

const handleParallerRequests = (action: ListFilterOptionsActionRequest, filterKeys: string[], currentUser) =>
  from(filterKeys).pipe(
    mergeMap((key) =>
      from(fetchFilterOptions({ ...action, key }, currentUser)).pipe(
        map((response) => ({ key, values: extractKeys(response) })),
      ),
    ),
    reduce((acc, { key, values }) => ({ ...acc, [key]: values }), {}),
    map((filterValues) =>
      listFilterOptionsSuccess({
        filterKeys,
        filterValues,
      }),
    ),
  );

export default function listFilterOptionsEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: ListFilterOptionsAction): action is ListFilterOptionsActionRequest =>
        action.type === FilterOptionsActionTypes.LIST_FILTER_OPTIONS_REQUEST,
    ),
    debounceTime(300),
    switchMap((action: ListFilterOptionsActionRequest) =>
      from(fetchFilterOptions(action, state$?.value?.login?.currentUser)).pipe(
        mergeMap((response) =>
          handleParallerRequests(action, extractKeys(response), state$?.value?.login?.currentUser),
        ),
        catchError((error) => of(listFilterOptionsFailed(error))),
      ),
    ),
  );
}
