/* External dependencies */
import gql from 'graphql-tag';

export const getServiceQuery = gql`
  query getService($input: ServiceInput!) {
    getService(input: $input) {
      currency
      fees {
        dynamicFeeSteps {
          fee {
            flatFee {
              amount
              currency
            }
            flatFeePercent
          }
          from {
            amount
            currency
          }
        }
        flatFee {
          amount
          currency
        }
        flatFeePercent
      }
      id
      devices {
        id
      }
      limit {
        id
      }
      name_en
      name_ru
      name_ky
      maxAmount
      minAmount
      slug
      status
      statusMessage
      logo {
        url
      }
      parentId
      popularityScore
      requiredFields {
        fieldId
        inputMask
        label_en
        label_ky
        label_ru
        maxLength
        minLength
        prefix
        keyboardType
        value
        isHidden
        excludeFromPayload
      }
      serviceEndpoint {
        id
        name
      }
      supportsDecimals
    }
  }
`;
