import { Item } from '../../../../components/services/types';

export type ItemQrCode = Pick<Item, 'id' | 'name_en' | 'name_ky' | 'name_ru' | 'qrCode'>;

export enum GetItemActionTypes {
  GET_ITEM_REQUEST = 'GET_ITEM_REQUEST',
  GET_ITEM_SUCCESS = 'GET_ITEM_SUCCESS',
  GET_ITEM_ERROR = 'GET_ITEM_ERROR',
  RESET_ITEM = 'RESET_ITEM',
}

export interface GetItem {
  type: GetItemActionTypes.GET_ITEM_REQUEST;
  id: string;
}

export interface GetItemSuccess {
  type: GetItemActionTypes.GET_ITEM_SUCCESS;
  item: ItemQrCode;
}

export interface GetItemError {
  type: GetItemActionTypes.GET_ITEM_ERROR;
  error: Error;
}

export interface ResetItem {
  type: GetItemActionTypes.RESET_ITEM;
}

export type GetItemAction = GetItem | GetItemSuccess | GetItemError | ResetItem;
