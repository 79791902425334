/* External dependencies */
import { debounceTime, filter, map, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../clients/averspay';
import { getUserRole, setLocalStorage } from '../../../components/common/helpers';
import { UserRole } from '../../../components/common/roles';
import { LoginAction, LoginActionTypes, SessionUser } from '../../../components/login/redux/actions';
import { getInitialDeveloperMode } from '../helper';
import {
  ListTransactions,
  ListTransactionsAction,
  ListTransactionsActionTypes,
  listTransactionsFailed,
  listTransactionsSucceeded,
  ListTransactionsSuccess,
  toggleTableMode,
} from './actions';
import { listPaymentsQuery } from './queries';
import { Transaction } from './types';

export default function listTransactionsEpic(action$, state$) {
  return action$.pipe(
    filter((action: ListTransactionsAction) => action.type === ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST),
    debounceTime(500),
    switchMap((action: ListTransactions) =>
      listTransactions(action, state$.value.transactions?.size, state$.value.login.currentUser).catch((error) =>
        listTransactionsFailed(error),
      ),
    ),
  );
}

export async function listTransactions(
  { currentPage, filter, locale, searchString, startDate, endDate }: ListTransactions,
  size: number,
  user: SessionUser,
): Promise<ListTransactionsSuccess> {
  const graphQLClient = await getClient();
  const userRole = getUserRole(user);

  const input = {
    filter,
    from: (currentPage - 1) * size,
    locale,
    query: searchString,
    size,
    ...(startDate ? { startDate: getFullTime('startDate', startDate) } : {}),
    ...(endDate ? { endDate: getFullTime('endDate', endDate) } : {}),
  };

  const response = await graphQLClient.query({
    errorPolicy: 'none',
    query: getQuery(userRole),
    variables: {
      input,
    },
  });

  const { data } = response;

  const { total, payments, totalAmount } = data.listTransactions ?? data.listPayments;

  const filteredPayments = payments.filter((payment: Transaction) => payment);

  return listTransactionsSucceeded(total, filteredPayments, totalAmount);
}

function getQuery(userRole: string) {
  switch (userRole) {
    case UserRole.USER:
    case UserRole.DEALER:
      return listPaymentsQuery;

    default:
      return listPaymentsQuery;
  }
}

export function getFullTime(type: 'startDate' | 'endDate', providedDate: string) {
  const date = new Date(providedDate);

  let timestamp = date.getTime();

  if (type === 'endDate') {
    timestamp = date.getTime() + 59 * 1000;
  }

  return timestamp;
}

export function setDefaultModeEpic(action$, state$) {
  return action$.pipe(
    filter((action: LoginAction) => action.type === LoginActionTypes.INIT_CLIENT_SUCCEEDED),
    map(() => {
      const currentUser = state$.value.login.currentUser;

      const isDeveloperMode = getInitialDeveloperMode(currentUser);

      setLocalStorage('isDeveloperMode', isDeveloperMode);

      return toggleTableMode(isDeveloperMode);
    }),
  );
}
