/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { User } from '../../../../components/users/userTypes';
import { CreateUserActions, CreateUserActionType, CreateUserOrganization } from './action';

export interface CreateUserState {
  loading: boolean;
  error?: Error;
  user?: User;
  isSuccess: boolean;
  userOrganization: CreateUserOrganization;
  isValid: boolean;
  confirmationModal: boolean;
}

export const initialCreateUserState: CreateUserState = {
  loading: false,
  isSuccess: false,
  userOrganization: {
    firstName: '',
    lastName: '',
    phone: '',
  },
  isValid: false,
  confirmationModal: false,
};

export const isCreateUserOrganizationValid = (createUserOrganization: CreateUserOrganization): boolean => {
  return Object.values(createUserOrganization).every((value) => value !== '');
};

export default function createUserReducer(state = initialCreateUserState, action: CreateUserActions): CreateUserState {
  switch (action.type) {
    case CreateUserActionType.CREATE_USER_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case CreateUserActionType.CREATE_USER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        user: { $set: action.payload },
        isSuccess: { $set: true },
      });

    case CreateUserActionType.CREATE_USER_FAILURE:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case CreateUserActionType.RESET_CREATE_USER:
      return initialCreateUserState;

    case CreateUserActionType.UPDATE_ORGANIZATION_FIELD: {
      const updatedOrganization = update(state.userOrganization, {
        [action.field]: { $set: action.value },
      });

      return update(state, {
        userOrganization: { $set: updatedOrganization },
        isValid: { $set: isCreateUserOrganizationValid(updatedOrganization) },
      });
    }

    case CreateUserActionType.CONFIRMATION_MODAL:
      return update(state, {
        confirmationModal: { $set: action.isOpen },
      });

    case CreateUserActionType.RESET_CREATE_USER_ERROR:
      return update(state, {
        error: { $set: null },
        confirmationModal: { $set: false },
      });

    default:
      return state;
  }
}
