/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { ServiceRecipientResponse } from '../types';
import { CheckRecipientAction, CheckRecipientActionTypes } from './actions';

export interface CheckRecipientState {
  error?: Error;
  loading: boolean;
  recipientData?: ServiceRecipientResponse;
  paymentData?: Object;
}

export const initialCheckRecipientState: CheckRecipientState = {
  loading: false,
};

export default function checkRecipientReducer(
  state = initialCheckRecipientState,
  action: CheckRecipientAction,
): CheckRecipientState {
  switch (action.type) {
    case CheckRecipientActionTypes.CHECK_RECIPIENT_REQUEST:
      const { payload } = action;

      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
        paymentData: { $set: payload },
        recipientData: { $set: null },
      });

    case CheckRecipientActionTypes.CHECK_RECIPIENT_SUCCESS:
      return update(state, {
        recipientData: { $set: action.recipientData },
        loading: { $set: false },
      });

    case CheckRecipientActionTypes.CHECK_RECIPIENT_ERROR:
      return update(state, {
        error: { $set: action.error },
        paymentData: { $set: null },
        recipientData: { $set: null },
        loading: { $set: false },
      });

    case CheckRecipientActionTypes.RESET_CHECK_RECIPIENT_STATE:
      return initialCheckRecipientState;

    case CheckRecipientActionTypes.RESET_CHECK_RECIPIENT_ERROR:
      return update(state, {
        error: { $set: null },
        loading: { $set: false },
      });

    default:
      return state;
  }
}
