/* Local dependencies */
import { StepsComponentActionsTypes, StepsComponentsActions } from './actions';
import { MenuLink } from './StepsComponent';

interface StepsComponentState {
  menuItem: MenuLink;
}

const initialStepsComponentState = {
  menuItem: null,
};

export function stepsComponentReducer(
  state: StepsComponentState = initialStepsComponentState,
  action: StepsComponentsActions,
) {
  switch (action.type) {
    case StepsComponentActionsTypes.SET_STEP_COMPONENT:
      return {
        menuItem: action.payload,
      };

    default:
      return state;
  }
}
