// Local dependencies
import {
  FilterOptionsActionTypes,
  Filters,
  ListFilterOptionsActionFailed,
  ListFilterOptionsActionRequest,
  ListFilterOptionsActionSuccess,
} from './types';

export function listFilterOptions(
  params?: Omit<ListFilterOptionsActionRequest, 'type' | 'userId'>,
): ListFilterOptionsActionRequest {
  const { key } = params || { key: '' };

  return {
    type: FilterOptionsActionTypes.LIST_FILTER_OPTIONS_REQUEST,
    key: key ?? '',
  };
}

export function listFilterOptionsSuccess(filters: Filters): ListFilterOptionsActionSuccess {
  return {
    type: FilterOptionsActionTypes.LIST_FILTER_OPTIONS_SUCCESS,
    filters,
  };
}

export function listFilterOptionsFailed(error: Error): ListFilterOptionsActionFailed {
  return {
    type: FilterOptionsActionTypes.LIST_FILTER_OPTIONS_FAILED,
    error,
  };
}
