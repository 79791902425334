/* Local dependencies */
import { UserStatus } from '../../../../components/users/userTypes';
import { Account, AccountType } from '../types';

export enum ListAccountsActionTypes {
  LIST_ACCOUNTS_REQUEST = 'LIST_ACCOUNTS_REQUEST',
  LIST_ACCOUNTS_SUCCESS = 'LIST_ACCOUNTS_SUCCESS',
  LIST_ACCOUNTS_ERROR = 'LIST_ACCOUNTS_ERROR',
  RESET_SELECTED_ACCOUNT = 'RESET_SELECTED_ACCOUNT',
  SELECT_ACCOUNT = 'SELECT_ACCOUNT',
  SORT_ACCOUNT_BY = 'SORT_ACCOUNT_BY',
  RESET_ERROR = 'RESET_ERROR',
  RESET_STATE = 'RESET_STATE',
}

export enum AccountsType {
  CORPORATE = 'CORPORATE',
  PERSONAL = 'PERSONAL',
  ROOT = 'ROOT',
}

export interface ListAccountsFilter {
  type?: AccountType | null;
  status?: UserStatus | null;
  userId?: string | null;
}

export interface ListAccounts {
  type: ListAccountsActionTypes.LIST_ACCOUNTS_REQUEST;
  currentPage?: number;
  filter?: ListAccountsFilter;
  searchString?: string;
  size?: number;
}

export interface ListAccountsSuccess {
  type: ListAccountsActionTypes.LIST_ACCOUNTS_SUCCESS;
  accounts: Account[];
  total: number;
  totalAmount: number;
}

export interface ListAccountsError {
  type: ListAccountsActionTypes.LIST_ACCOUNTS_ERROR;
  error: Error;
}

export interface ResetListAccountsError {
  type: ListAccountsActionTypes.RESET_ERROR;
}

export interface ListAccountsSort {
  type: ListAccountsActionTypes.SORT_ACCOUNT_BY;
  field: string;
}

export interface SelectAccount {
  type: ListAccountsActionTypes.SELECT_ACCOUNT;
  account: Account;
  single: boolean;
}

export interface ResetSelectedAccount {
  type: ListAccountsActionTypes.RESET_SELECTED_ACCOUNT;
}

export interface ResetListAccountsState {
  type: ListAccountsActionTypes.RESET_STATE;
}

export const DEFAULT_ACCOUNT_TYPE: ListAccountsFilter = {
  type: AccountType.PERSONAL,
};

export type ListAccountsAction =
  | ListAccounts
  | ListAccountsSuccess
  | ListAccountsError
  | SelectAccount
  | ResetSelectedAccount
  | ResetListAccountsError
  | ResetListAccountsState;

interface ListAccountsProps {
  searchString?: string;
  currentPage?: number;
  filter?: ListAccountsFilter;
  size?: number;
}

export function listAccounts({
  searchString = '',
  currentPage = 1,
  filter = DEFAULT_ACCOUNT_TYPE,
  size = 20,
}: ListAccountsProps): ListAccounts {
  return {
    type: ListAccountsActionTypes.LIST_ACCOUNTS_REQUEST,
    searchString,
    currentPage,
    filter,
    size,
  };
}

export function listAccountsSucceeded(total, accounts, totalAmount): ListAccountsSuccess {
  return {
    type: ListAccountsActionTypes.LIST_ACCOUNTS_SUCCESS,
    accounts,
    total,
    totalAmount,
  };
}

export function listAccountsFailed(error: Error): ListAccountsError {
  return {
    type: ListAccountsActionTypes.LIST_ACCOUNTS_ERROR,
    error,
  };
}

export function sortAccountsBy(field: string) {
  return {
    type: ListAccountsActionTypes.SORT_ACCOUNT_BY,
    field,
  };
}

export function selectAccount(account: Account, single: boolean = false): SelectAccount {
  return {
    type: ListAccountsActionTypes.SELECT_ACCOUNT,
    account,
    single,
  };
}

export function resetSelectedAccount() {
  return {
    type: ListAccountsActionTypes.RESET_SELECTED_ACCOUNT,
  };
}

export function resetListAccountsError() {
  return {
    type: ListAccountsActionTypes.RESET_ERROR,
  };
}

export function resetListAccountsState() {
  return {
    type: ListAccountsActionTypes.RESET_STATE,
  };
}
