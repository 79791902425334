// Local dependencies
import { Account } from '../../../accounts/listAccounts/types';
import {
  CloseSuccessPopup,
  HideConfirmationDeleteSAccountPopup,
  ResetUpdateAccount,
  ResetUpdateAccountError,
  ShowConfirmationDeleteAccountPopup,
  UpdateAccount,
  UpdateAccountActionTypes,
  UpdateAccountError,
  UpdateAccountFields,
  UpdateAccountInput,
  UpdateAccountSuccess,
} from './types';

export const updateAccount = (accountFields: UpdateAccountInput): UpdateAccount => ({
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_REQUEST,
  accountFields,
});

export const updateAccountSuccess = (account: Account): UpdateAccountSuccess => ({
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_SUCCESS,
  account,
});

export const updateAccountError = (error: Error): UpdateAccountError => ({
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_ERROR,
  error,
});

export const resetUpdateAccount = (): ResetUpdateAccount => ({
  type: UpdateAccountActionTypes.RESET_UPDATE_ACCOUNT,
});

export const resetUpdateAccountError = (): ResetUpdateAccountError => ({
  type: UpdateAccountActionTypes.RESET_UPDATE_ACCOUNT_ERROR,
});

export function showConfirmationAccountPopup(): ShowConfirmationDeleteAccountPopup {
  return {
    type: UpdateAccountActionTypes.SHOW_CONFIRMATION_ACCOUNT_POPUP,
  };
}

export function hideConfirmationAccountPopup(): HideConfirmationDeleteSAccountPopup {
  return {
    type: UpdateAccountActionTypes.HIDE_CONFIRMATION_ACCOUNT_POPUP,
  };
}

export function closeSuccessPopup(): CloseSuccessPopup {
  return {
    type: UpdateAccountActionTypes.CLOSE_SUCCESS_POPUP,
  };
}

export function updateAccountFields(accountFields: UpdateAccountInput): UpdateAccountFields {
  return {
    type: UpdateAccountActionTypes.UPDATE_ACCOUNT_FIELDS,
    accountFields,
  };
}
