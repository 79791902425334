/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import {
  MakePaymentAction,
  MakePaymentActionTypes,
  makePaymentSuccess,
  makePaymentError,
  MakePaymentRequest,
} from './actions';
import { getMakePaymentMutation } from './mutation';

export default function makePaymentEpic(action$) {
  return action$.pipe(
    filter((action: MakePaymentAction) => action.type === MakePaymentActionTypes.MAKE_PAYMENT_REQUEST),
    switchMap((action: MakePaymentRequest) => makePayment(action).then(makePaymentSuccess).catch(makePaymentError)),
  );
}

export async function makePayment(action: MakePaymentRequest): Promise<any> {
  const graphQLClient = await getClient();
  const { payload } = action;

  const {
    data: { makePayment },
  } = await graphQLClient.mutate({
    mutation: getMakePaymentMutation,
    variables: {
      input: payload,
    },
  });

  return makePayment;
}
