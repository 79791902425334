/* Local dependencies */
import { Account } from '../../../../components/accounts/listAccounts/types';
import { UserStatus } from '../../../../components/users/userTypes';

export interface UpdateForm {
  id: string;
  name: string;
  status: string;
  statusReason: string;
}

export enum UpdateAccountActionTypes {
  UPDATE_ACCOUNT_REQUEST = 'UPDATE_ACCOUNT_REQUEST',
  UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS',
  UPDATE_ACCOUNT_ERROR = 'UPDATE_ACCOUNT_ERROR',

  RESET_UPDATE_ACCOUNT = 'RESET_UPDATE_ACCOUNT',
  RESET_UPDATE_ACCOUNT_ERROR = 'RESET_UPDATE_ACCOUNT_ERROR',

  SHOW_CONFIRMATION_ACCOUNT_POPUP = 'SHOW_CONFIRMATION_ACCOUNT_POPUP',
  HIDE_CONFIRMATION_ACCOUNT_POPUP = 'HIDE_CONFIRMATION_ACCOUNT_POPUP',
  CLOSE_SUCCESS_POPUP = 'CLOSE_SUCCESS_POPUP',

  UPDATE_ACCOUNT_FIELDS = 'UPDATE_ACCOUNT_FIELDS',
}

export interface AccountStatusInput {
  statusReason?: string;
  statusType?: UserStatus;
}

export interface UpdateAccountInput {
  id: string;
  name?: string;
  isDefault?: boolean;
  status?: AccountStatusInput;
}

export interface ResetUpdateAccount {
  type: UpdateAccountActionTypes.RESET_UPDATE_ACCOUNT;
}

export interface ResetUpdateAccountError {
  type: UpdateAccountActionTypes.RESET_UPDATE_ACCOUNT_ERROR;
}

export interface CloseSuccessPopup {
  type: UpdateAccountActionTypes.CLOSE_SUCCESS_POPUP;
}

export interface UpdateAccount {
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_REQUEST;
  accountFields: UpdateAccountInput;
}

export interface UpdateAccountSuccess {
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_SUCCESS;
  account: Account;
}

export interface UpdateAccountError {
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_ERROR;
  error: Error;
}

export interface ShowConfirmationDeleteAccountPopup {
  type: UpdateAccountActionTypes.SHOW_CONFIRMATION_ACCOUNT_POPUP;
}

export interface HideConfirmationDeleteSAccountPopup {
  type: UpdateAccountActionTypes.HIDE_CONFIRMATION_ACCOUNT_POPUP;
}

export interface UpdateAccountFields {
  type: UpdateAccountActionTypes.UPDATE_ACCOUNT_FIELDS;
  accountFields: UpdateAccountInput;
}

export type UpdateAccountAction =
  | UpdateAccount
  | UpdateAccountSuccess
  | UpdateAccountError
  | ResetUpdateAccount
  | ResetUpdateAccountError
  | ShowConfirmationDeleteAccountPopup
  | HideConfirmationDeleteSAccountPopup
  | CloseSuccessPopup
  | UpdateAccountFields;
