/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { Item } from 'components/services/types';
import { UpdateItemActions, UpdateItemActionTypes } from './types';

export interface UpdateItemState {
  loading: boolean;
  item: Item;
  success: boolean;
  error: Error | null;
  confirmationPopup: boolean;
}

export const initialUpdateItemState: UpdateItemState = {
  loading: false,
  item: null,
  error: null,
  success: false,
  confirmationPopup: false,
};

export default function updateItemReducer(state = initialUpdateItemState, action: UpdateItemActions): UpdateItemState {
  switch (action.type) {
    case UpdateItemActionTypes.UPDATE_ITEM:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case UpdateItemActionTypes.UPDATE_ITEM_SUCCESS:
      return update(state, {
        item: { $set: action.item },
        confirmationPopup: { $set: false },
        loading: { $set: false },
        success: { $set: true },
      });

    case UpdateItemActionTypes.UPDATE_ITEM_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case UpdateItemActionTypes.OPEN_CONFIRMATION_POPUP:
      return update(state, {
        confirmationPopup: { $set: true },
      });

    case UpdateItemActionTypes.CLOSE_CONFIRMATION_POPUP:
      return update(state, {
        confirmationPopup: { $set: false },
      });

    case UpdateItemActionTypes.CLOSE_SUCCESS_POPUP:
      return update(state, {
        success: { $set: false },
      });

    case UpdateItemActionTypes.RESET_UPDATE_ITEM_ERROR:
      return update(state, {
        error: { $set: null },
      });

    default:
      return state;
  }
}
