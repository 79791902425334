/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import {
  AccountPermissionsToUserActions,
  AccountPermissionsToUserInput,
  AccountPermissionsToUserRequest,
  AccountPermissionsToUserTypes,
  setAccountPermissionsToUserError,
  setAccountPermissionsToUserSuccess,
} from './actions';
import { setAccountPermissionsToUserMutation } from './mutation';

export default function setAccountPermissionsToUserEpic(action$) {
  return action$.pipe(
    filter(
      (action: AccountPermissionsToUserActions) =>
        action.type === AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_REQUEST,
    ),
    switchMap((action: AccountPermissionsToUserRequest) =>
      setAccountPermissionsToUser(action.payload)
        .then((statusMessage) => setAccountPermissionsToUserSuccess(statusMessage))
        .catch(setAccountPermissionsToUserError),
    ),
  );
}

export async function setAccountPermissionsToUser(input: AccountPermissionsToUserInput): Promise<string> {
  const graphQLClient = await getClient();

  const {
    data: { setAccountPermissionsToUser },
  } = await graphQLClient.mutate({
    mutation: setAccountPermissionsToUserMutation,
    variables: {
      input: {
        permissionId: input.accountId,
        permissions: input.permissions,
        phone: input.phone,
        type: 'ACCOUNT',
      },
    },
  });

  return setAccountPermissionsToUser;
}
