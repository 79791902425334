// External dependecies
import { filter, switchMap } from 'rxjs';

// Local dependecies
import { getClient } from '../../../../clients/averspay';
import { updateItemError, updateItemSuccess } from './actions';
import { updateItemMutation } from './mutations';
import { UpdateItem, UpdateItemActions, UpdateItemActionTypes, UpdateItemSuccess } from './types';

export default function updateItemEpic(action$) {
  return action$.pipe(
    filter((action: UpdateItemActions) => action.type === UpdateItemActionTypes.UPDATE_ITEM),
    switchMap((action: UpdateItem) => updateItem(action).catch((error) => updateItemError(error))),
  );
}

async function updateItem(input: UpdateItem): Promise<UpdateItemSuccess> {
  const graphQLClient = await getClient();

  delete input.type;

  const {
    data: { item },
  } = await graphQLClient.mutate({
    mutation: updateItemMutation,
    variables: {
      input,
    },
  });

  return updateItemSuccess(item);
}
