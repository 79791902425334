import { gql } from '@apollo/client';

export const getItemQuery = gql`
  query getItem($input: ServiceInput!) {
    getItem(input: $input) {
      id
      name_en
      name_ru
      name_ky
      qrCode {
        image
      }
    }
  }
`;
