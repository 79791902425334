/* External dependencies */
import update from 'immutability-helper';
 
/* Local dependencies */
import { PermissionsType } from '../types';
import { AccountPermissionsToUserActions, AccountPermissionsToUserTypes } from './actions';

export interface AccountPermissionsToUserState {
  error?: Error;
  loading?: boolean;
  phone: string;
  permissions: Record<PermissionsType, boolean>;
  selectedPermissions: PermissionsType[];
  isSuccess: boolean;
}

export const initialAccountPermissionsToUserState: AccountPermissionsToUserState = {
  loading: false,
  error: null,
  phone: '',
  permissions: {
    [PermissionsType.VIEW_ACCOUNT_BALANCE]: false,
    [PermissionsType.VIEW_PAYMENTS_HISTORY]: false,
    [PermissionsType.VIEW_DEBIT_PAYMENTS_HISTORY]: false,
    [PermissionsType.VIEW_CREDIT_PAYMENTS_HISTORY]: false,
    [PermissionsType.UPDATE_ACCOUNT]: false,
    [PermissionsType.MAKE_PAYMENT]: false,
    [PermissionsType.MANAGE_ACCESS]: false,
    [PermissionsType.CREATE_ITEM]: false,
    [PermissionsType.DELETE_ITEM]: false,
    [PermissionsType.UPDATE_ITEM]: false,
  },
  selectedPermissions: [],
  isSuccess: false,
};

export default function accountPermissionsToUserReducer(
  state = initialAccountPermissionsToUserState,
  action: AccountPermissionsToUserActions,
) {
  switch (action.type) {
    case AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_SUCCESS:
      return update(state, {
        loading: { $set: false },
        isSuccess: { $set: true },
      });

    case AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_RESET:
      return initialAccountPermissionsToUserState;

    case AccountPermissionsToUserTypes.ACCOUNT_PERMISSIONS_TO_USER_RESET_ERROR:
      return update(state, {
        error: { $set: null },
      });

    case AccountPermissionsToUserTypes.PERMISSION_PHONE:
      return update(state, {
        phone: { $set: action.phone },
      });

    case AccountPermissionsToUserTypes.SET_SELECTED_PERMISSIONS:
      const updatedPermissions = update(state.permissions, {
        [action.permissions]: { $apply: (value: boolean) => !value },
      });

      return update(state, {
        permissions: { $set: updatedPermissions },
        selectedPermissions: {
          $set: Object.entries(updatedPermissions)
            .filter(([_, isEnabled]) => isEnabled)
            .map(([permType]) => permType as PermissionsType),
        },
      });

    default:
      return state;
  }
}