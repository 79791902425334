// Local dependencies
import { Item, ServiceStatus } from '../../../services/types';
import {
  CloseConfirmationPopup,
  CloseSuccessModal,
  OpenConfirmationPopup,
  ResetUpdateItemError,
  UpdateItem,
  UpdateItemActionTypes,
  UpdateItemError,
  UpdateItemSuccess,
} from './types';

interface UpdateItemInput {
  id: string;
  status: ServiceStatus;
}

export function updateItem(item: UpdateItemInput): UpdateItem {
  return {
    type: UpdateItemActionTypes.UPDATE_ITEM,
    ...item,
  };
}

export function updateItemSuccess(item: Item): UpdateItemSuccess {
  return {
    type: UpdateItemActionTypes.UPDATE_ITEM_SUCCESS,
    item,
  };
}

export function updateItemError(error: Error): UpdateItemError {
  return {
    type: UpdateItemActionTypes.UPDATE_ITEM_ERROR,
    error,
  };
}

export function openConfirmationPopup(): OpenConfirmationPopup {
  return {
    type: UpdateItemActionTypes.OPEN_CONFIRMATION_POPUP,
  };
}

export function closeConfirmationPopup(): CloseConfirmationPopup {
  return {
    type: UpdateItemActionTypes.CLOSE_CONFIRMATION_POPUP,
  };
}

export function closeSuccessModal(): CloseSuccessModal {
  return {
    type: UpdateItemActionTypes.CLOSE_SUCCESS_POPUP,
  };
}

export function resetUpdateItemError(): ResetUpdateItemError {
  return {
    type: UpdateItemActionTypes.RESET_UPDATE_ITEM_ERROR,
  };
}
