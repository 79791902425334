/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { getUserRole } from '../../../../components/common/helpers';
import { UserRole } from '../../../../components/common/roles';
import { SessionUser } from '../../../../components/login/redux/actions';
import { ListTransactionsFilter } from '../../../../components/transactions/listTransactions/actions';
import { getFullTime } from '../../../../components/transactions/listTransactions/epics';
import {
  DownloadTransactionsAction,
  DownloadTransactionsActionTypes,
  downloadTransactionsFailed,
  downloadTransactionsSucceeded,
  DownloadTransactionsSuccess,
} from './actions';
import { initiateTransactionsExportQuery } from './mutations';

export default function downloadTransactionsEpic(action$, state$) {
  return action$.pipe(
    filter(
      (action: DownloadTransactionsAction) =>
        action.type === DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_REQUEST,
    ),
    switchMap(() =>
      downloadTransactions(
        state$.value.transactions?.filter,
        state$.value.transactions?.locale,
        state$.value.transactions?.searchString,
        state$.value.transactions?.startDate,
        state$.value.transactions?.endDate,
        state$.value.login.currentUser,
      )
        .then((action) => downloadTransactionsSucceeded(action.id, action.url))
        .catch((error) => downloadTransactionsFailed(error)),
    ),
  );
}

export async function downloadTransactions(
  filter: ListTransactionsFilter,
  locale: string,
  searchString: string,
  startDate: string,
  endDate: string,
  user: SessionUser,
): Promise<DownloadTransactionsSuccess> {
  const graphQLClient = await getClient();

  const userRole = getUserRole(user);

  console.log('user role', userRole);

  switch (userRole) {
    case UserRole.USER:
    case UserRole.DEALER:
      filter.userId = user.sub;
  }

  const input = {
    filter,
    locale,
    query: searchString,
    ...(startDate ? { startDate: getFullTime('startDate', startDate) } : {}),
    ...(endDate ? { endDate: getFullTime('endDate', endDate) } : {}),
  };

  const response = await graphQLClient.query({
    errorPolicy: 'none',
    query: initiateTransactionsExportQuery,
    variables: {
      input,
    },
  });

  const {
    data: {
      initiateTransactionsExport: { id, url },
    },
  } = response;

  return downloadTransactionsSucceeded(id, url);
}
