/* External dependencies */
import gql from 'graphql-tag';

export const getClientServiceLimitsQuery = gql`
  query getClientServiceLimits($input: GetClientServiceLimitsInput!) {
    getClientServiceLimits(input: $input) {
      serviceLimit
    }
  }
`;
