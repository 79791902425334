/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { DownloadTransactionsAction, DownloadTransactionsActionTypes } from './actions';

export interface DownloadTransactionsState {
  error?: Error;
  loading?: boolean;
  initiateTransactionsExportResponseId?: string;
  url?: string;
}

export const initialDownloadTransactionsState: DownloadTransactionsState = {
  loading: false,
  initiateTransactionsExportResponseId: '',
  url: '',
};

export default function downloadTransactionsReducer(
  state = initialDownloadTransactionsState,
  action: DownloadTransactionsAction,
) {
  switch (action.type) {
    case DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_SUCCESS:
      return update(state, {
        initiateTransactionsExportResponseId: { $set: action.id },
        url: { $set: action.url },
      });

    case DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_ERROR_RESET:
      return update(state, {
        error: { $set: null },
      });

    case DownloadTransactionsActionTypes.DOWNLOAD_TRANSACTIONS_RESET:
      return initialDownloadTransactionsState;

    case DownloadTransactionsActionTypes.DISABLE_SUBSCRIPTION:
      return update(state, {
        loading: { $set: false },
      });

    default:
      return state;
  }
}
