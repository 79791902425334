/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { getAccountError, getAccountSuccess } from './actions';
import { getAccountQuery } from './queries';
import { GetAccountActions, GetAccountActionTypes, GetAccountRequest } from './types';

export function getAccountEpic(actions$) {
  return actions$.pipe(
    filter((action: GetAccountActions) => action.type === GetAccountActionTypes.GET_ACCOUNT_REQUEST),
    switchMap((action: GetAccountRequest) => getAccount(action).catch((error) => getAccountError(error))),
  );
}

async function getAccount(input: GetAccountRequest) {
  const { id } = input;
  const graphQLClient = await getClient();

  const {
    data: { getAccount },
  } = await graphQLClient.query({
    query: getAccountQuery,
    variables: {
      input: {
        id,
      },
    },
  });

  return getAccountSuccess(getAccount);
}
