// External dependencies
import update from 'immutability-helper';

/* Local dependencies */
import { CreateItemActions, CreateItemActionTypes, CreateItemInput } from './action';

export interface CreateItemState {
  loading: boolean;
  error: Error;
  item?: CreateItemInput;
  confirmationModal: boolean;
  itemNameError?: string;
  isFormValid: boolean;
  successPopup: boolean;
}


const initialState: CreateItemState = {
  error: null,
  loading: false,
  isFormValid: false,
  successPopup: false,
  itemNameError: undefined,
  confirmationModal: false,
  item: {
    name_en: '',
    callbackUrl: '',
    maxAvailableQuantity: null,
    requiredFields: [
      {
        fieldId: '',
        value: '',
      },
    ],
    fixedAmount: null,
    startDate: new Date().valueOf(),
    endDate: new Date().valueOf(),
  },
};



export default function createItemReducer(
  state: CreateItemState = initialState,
  action: CreateItemActions,
): CreateItemState {
  switch (action.type) {
    case CreateItemActionTypes.CREATE_ITEM_REQUEST:
      return update(state, {
        loading: { $set: true },
      });

    case CreateItemActionTypes.CREATE_ITEM_SUCCESS:
      return update(state, {
        loading: { $set: false },
        item: { $set: { ...initialState.item, ...action.payload } },
        successPopup: { $set: true },
      });

    case CreateItemActionTypes.CREATE_ITEM_ERROR:
      return update(state, {
        loading: { $set: false },
        error: { $set: action.error },
      });

    case CreateItemActionTypes.RESET_CREATE_ITEM_ERROR:
      return update(state, {
        error: { $set: null },
      });

    case CreateItemActionTypes.RESET_CREATE_ITEM:
      return initialState;

    case CreateItemActionTypes.OPEN_CONFIRMATION_MODAL:
      return update(state, {
        confirmationModal: { $set: true },
      });

    case CreateItemActionTypes.CLOSE_CONFIRMATION_MODAL:
      return update(state, {
        confirmationModal: { $set: false },
      });

    case CreateItemActionTypes.ITEM_FORM_VALIDITY_UPDATE:
      return update(state, {
        isFormValid: { $set: action.payload },
      });

    case CreateItemActionTypes.ITEM_SUCCESS_POPUP_CLOSE:
      return update(state, {
        successPopup: { $set: false },
      });

    case CreateItemActionTypes.ITEM_UPDATE_REQUIRED_FIELDS:
      return update(state, {
        item: {
          requiredFields: {
            [action.index]: {
              [action.field]: { $set: action.value },
            },
          },
        },
      });

    case CreateItemActionTypes.ITEM_UPDATE_FIELDS:
      return update(state, {
        item: { $set: action.payload },
      });

    default:
      return state;
  }
}
