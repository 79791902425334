// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { ClientServiceLimits } from '../types';
import { GetClientServiceLimitsAction, GetClientServiceLimitsActionTypes } from './actions';

export interface GetClientServiceLimitsState {
  error?: Error;
  loading: boolean;
  limits?: ClientServiceLimits;
}

export const initialGetClientServiceLimitsState: GetClientServiceLimitsState = {
  loading: false,
};

export default function getClientServiceLimitsReducer(
  state = initialGetClientServiceLimitsState,
  action: GetClientServiceLimitsAction,
): GetClientServiceLimitsState {
  switch (action.type) {
    case GetClientServiceLimitsActionTypes.GET_CLIENT_SERVICE_LIMITS_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case GetClientServiceLimitsActionTypes.GET_CLIENT_SERVICE_LIMITS_SUCCESS:
      return update(state, {
        limits: { $set: action.limits },
        loading: { $set: false },
      });

    case GetClientServiceLimitsActionTypes.GET_CLIENT_SERVICE_LIMITS_ERROR:
      return update(state, {
        error: { $set: action.error },
        loading: { $set: false },
      });

    case GetClientServiceLimitsActionTypes.RESET_GET_CLIENT_SERVICE_LIMITS_STATE:
      return initialGetClientServiceLimitsState;

    default:
      return state;
  }
}
