/* External dependencies */
import { filter, switchMap } from 'rxjs/operators';

/* Local dependencies */
import { getClient } from '../../../../clients/averspay';
import { Account } from '../../../../components/accounts/listAccounts/types';
import { getUserRole } from '../../../../components/common/helpers';
import roles, { UserRole } from '../../../../components/common/roles';
import { SessionUser } from '../../../../components/login/redux/actions';
import { hasAccess } from '../../../../permissions/page-permissions';
import { updateAccountError, updateAccountSuccess } from './action';
import { updateAccountForAdminPanelMutation, updateAccountMutation } from './mutation';
import { UpdateAccount, UpdateAccountAction, UpdateAccountActionTypes, UpdateAccountInput, UpdateForm } from './types';

const rolesWithUpdateAccount = roles.filter((role) => role !== UserRole.ADMIN && role !== UserRole.MANAGER);

export default function updateAccountEpic(action$, state$) {
  return action$.pipe(
    filter((action: UpdateAccountAction) => action.type === UpdateAccountActionTypes.UPDATE_ACCOUNT_REQUEST),
    switchMap((action: UpdateAccount) =>
      updateAccount(action.accountFields, state$?.value?.login?.currentUser)
        .then(updateAccountSuccess)
        .catch(updateAccountError),
    ),
  );
}

export async function updateAccount(input: UpdateAccountInput, currentUser: SessionUser): Promise<Account> {
  const graphQLClient = await getClient();

  const userRole = getUserRole(currentUser);
  const hasUpdateAccountAccess = hasAccess(rolesWithUpdateAccount, userRole);

  const mutation = hasUpdateAccountAccess ? updateAccountMutation : updateAccountForAdminPanelMutation;

  const {
    data: { updateAccount },
  } = await graphQLClient.mutate({
    mutation,
    variables: {
      input,
    },
  });

  return updateAccount;
}
