// External dependencies
import update from 'immutability-helper';

// Local dependencies
import { FilterOptionsActionTypes, FilterOptionsState, ListFilterOptionsAction } from './types';

export const initialFilterOptionsState: FilterOptionsState = {
  filterKeys: [],
  filterValues: {},
  loading: false,
  error: null,
};

export function filterOptionsReducer(state = initialFilterOptionsState, action: ListFilterOptionsAction) {
  switch (action.type) {
    case FilterOptionsActionTypes.LIST_FILTER_OPTIONS_REQUEST:
      return update(state, {
        $unset: ['error'],
        loading: { $set: true },
      });

    case FilterOptionsActionTypes.LIST_FILTER_OPTIONS_SUCCESS:
      const { filters } = action;
      return update(state, {
        filterKeys: { $set: [...new Set([...state.filterKeys, ...(filters.filterKeys ?? state.filterKeys)])] },
        filterValues: { $set: { ...state.filterValues, ...filters.filterValues } },
        loading: { $set: false },
      });

    case FilterOptionsActionTypes.LIST_FILTER_OPTIONS_FAILED:
      const { error } = action;
      return update(state, {
        error: { $set: error },
        loading: { $set: false },
      });

    default:
      return state;
  }
}
