/* External dependencies */
import update from 'immutability-helper';

/* Local dependencies */
import { NameRequiredException } from '../../../../components/devices/terminals/updateTerminal/redux/exceptions';
import { StatusRequiredException } from '../../../../components/services/createService/redux/exceptions';
import { UpdateAccountState } from './reducer';
import { AccountStatusInput, UpdateAccountInput } from './types';

export function validateAccountFields(state: UpdateAccountState, updates: UpdateAccountInput) {
  const { name, status } = updates;
  const change: any = {
    isFormChanged: {
      $set: true,
    },
  };

  // Prepare updated fields object
  const updatedFields = {};

  // Validate name if it's being updated
  if (updates.hasOwnProperty('name')) {
    // Always update the field value, even if it's empty
    updatedFields['name'] = { $set: name };
    
    if (!name) {
      change.nameError = {
        $set: new NameRequiredException(),
      };
    } else {
      change['$unset'] = change['$unset'] || [];
      if (!change['$unset'].includes('nameError')) {
        change['$unset'].push('nameError');
      }
    }
  }

  // Validate status if it's being updated
  if (updates.hasOwnProperty('status')) {
    // Always update the status object
    updatedFields['status'] = { $set: status };
    
    if (!status?.statusType) {
      change.statusError = {
        $set: new StatusRequiredException(),
      };
    } else {
      change['$unset'] = change['$unset'] || [];
      if (!change['$unset'].includes('statusError')) {
        change['$unset'].push('statusError');
      }
    }
  }

  // Process all other fields (except statusType/statusReason which should be in status object)
  Object.keys(updates).forEach((key) => {
    if (updates.hasOwnProperty(key) && 
        key !== 'name' && 
        key !== 'status' && 
        key !== 'statusType' && 
        key !== 'statusReason') {
      updatedFields[key] = { $set: updates[key] };
    }
  });

  // Apply all updates at once
  if (Object.keys(updatedFields).length) {
    return update(state, {
      ...change,
      accountFields: {
        $set: update(state.accountFields, updatedFields),
      },
    });
  }

  return update(state, {
    ...change,
  });
}
