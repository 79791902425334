export enum PermissionsType {
  VIEW_ACCOUNT_BALANCE = 'VIEW_ACCOUNT_BALANCE',
  VIEW_PAYMENTS_HISTORY = 'VIEW_PAYMENTS_HISTORY',
  VIEW_DEBIT_PAYMENTS_HISTORY = 'VIEW_DEBIT_PAYMENTS_HISTORY',
  VIEW_CREDIT_PAYMENTS_HISTORY = 'VIEW_CREDIT_PAYMENTS_HISTORY',
  UPDATE_ACCOUNT = 'UPDATE_ACCOUNT',
  MAKE_PAYMENT = 'MAKE_PAYMENT',
  CREATE_ITEM = 'CREATE_ITEM',
  DELETE_ITEM = 'DELETE_ITEM',
  UPDATE_ITEM = 'UPDATE_ITEM',
  MANAGE_ACCESS = 'MANAGE_ACCESS', 
}
