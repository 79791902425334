// Local dependencies
import { Account } from '../../listAccounts/types';

export enum GetAccountActionTypes {
  GET_ACCOUNT_REQUEST = 'GET_ACCOUNT_REQUEST',
  GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_ERROR = 'GET_ACCOUNT_ERROR',
  RESET_STATE = 'RESET_STATE',
}

export interface GetAccountRequest {
  type: GetAccountActionTypes.GET_ACCOUNT_REQUEST;
  id: string;
}

export interface GetAccountSuccess {
  type: GetAccountActionTypes.GET_ACCOUNT_SUCCESS;
  account: Account;
}

export interface GetAccountError {
  type: GetAccountActionTypes.GET_ACCOUNT_ERROR;
  error: Error;
}

export interface ResetGetAccountState {
  type: GetAccountActionTypes.RESET_STATE;
}

export type GetAccountActions = GetAccountRequest | GetAccountSuccess | GetAccountError | ResetGetAccountState;
