// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local depedencies
import { getClient } from '../../../../../clients/averspay';
import { hasAccess } from '../../../../../permissions/page-permissions';
import { Account } from '../../../../accounts/listAccounts/types';
import { getUserRole } from '../../../../common/helpers';
import { UserRole } from '../../../../common/roles';
import { SessionUser } from '../../../../login/redux/actions';
import {
  CreateAccountAction,
  CreateAccountActionType,
  createAccountFailure,
  CreateAccountRequest,
  createAccountSucceeded,
} from './action';
import { createAccountForAdminPanelMutation, createAccountMutation } from './mutation';

const rolesWithCreateAccount = [
  UserRole.CASHIER,
  UserRole.COMPLIANCE_MANAGER,
  UserRole.DEALER,
  UserRole.OPERATOR,
  UserRole.TECHNICIAN,
  UserRole.USER,
];

export default function createAccountEpic(action$, state$) {
  return action$.pipe(
    filter((action: CreateAccountAction) => action.type === CreateAccountActionType.CREATE_ACCOUNT_REQUEST),
    switchMap((action: CreateAccountRequest) =>
      createAccount(action, state$?.value?.login?.currentUser).then(createAccountSucceeded).catch(createAccountFailure),
    ),
  );
}

export async function createAccount(input: CreateAccountRequest, currentUser: SessionUser): Promise<Account> {
  const params = { ...input };

  const graphQLClient = await getClient();
  const userRole = getUserRole(currentUser);
  const hasCreateAccountAccess = hasAccess(rolesWithCreateAccount, userRole);

  const mutation = hasCreateAccountAccess ? createAccountMutation : createAccountForAdminPanelMutation;

  if (hasCreateAccountAccess) params.user.id = currentUser.sub;

  delete params.type;

  const {
    data: { createAccount },
  } = await graphQLClient.mutate({
    mutation,
    variables: {
      input: params,
    },
  });

  return createAccount;
}
