/* External dependencies */
import { filter, switchMap } from 'rxjs';

/* Local dependencies */
import { getClient } from '../../../../../clients/averspay';
import { getServiceRecipientQuery } from '../../../../../components/getServiceRecipient/redux/queries';
import {
  CheckRecipientAction,
  CheckRecipientActionTypes,
  checkRecipientError,
  CheckRecipientRequest,
  checkRecipientSuccess,
} from './actions';

export default function checkRecipientEpic(action$) {
  return action$.pipe(
    filter((action: CheckRecipientAction) => action.type === CheckRecipientActionTypes.CHECK_RECIPIENT_REQUEST),
    switchMap((action: CheckRecipientRequest) =>
      checkRecipient(action).then(checkRecipientSuccess).catch(checkRecipientError),
    ),
  );
}

export async function checkRecipient(action: CheckRecipientRequest): Promise<any> {
  const graphQLClient = await getClient();
  const { payload } = action;

  const {
    data: { getServiceRecipient },
    errors,
  } = await graphQLClient.query({
    query: getServiceRecipientQuery,
    variables: { input: payload },
  });

  if (errors && errors.length) {
    throw new Error(errors[0].message);
  }

  return getServiceRecipient;
}
