export interface Filters {
  filterKeys?: string[];
  filterValues?: {
    [key: string]: string[];
  };
}

export interface FilterOptionsState extends Filters {
  loading: boolean;
  error?: Error;
}

export enum FilterOptionsActionTypes {
  LIST_FILTER_OPTIONS_REQUEST = 'LIST_FILTER_OPTIONS_REQUEST',
  LIST_FILTER_OPTIONS_FAILED = 'LIST_FILTER_OPTIONS_FAILED',
  LIST_FILTER_OPTIONS_SUCCESS = 'LIST_FILTER_OPTIONS_SUCCESS',
}

export interface ListFilterOptionsActionRequest {
  type: FilterOptionsActionTypes.LIST_FILTER_OPTIONS_REQUEST;
  key?: string;
}

export interface ListFilterOptionsActionSuccess {
  type: FilterOptionsActionTypes.LIST_FILTER_OPTIONS_SUCCESS;
  filters: Filters;
}

export interface ListFilterOptionsActionFailed {
  type: FilterOptionsActionTypes.LIST_FILTER_OPTIONS_FAILED;
  error: Error;
}

export type ListFilterOptionsAction =
  | ListFilterOptionsActionRequest
  | ListFilterOptionsActionSuccess
  | ListFilterOptionsActionFailed;

export interface ListFilterOptionsResponse {
  total: number;
  results: Array<{ key: string }>;
}
