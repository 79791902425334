/* Local dependencies */
import { Account } from '../../../../components/accounts/listAccounts/types';
import { RequiredServiceField } from '../../../../components/item/types';
import { Currency, ServiceFees } from '../../../../components/services/types';
import { Item } from '../../../../components/ui/selectedItems/types';

export enum CreateItemActionTypes {
  CREATE_ITEM_REQUEST = 'CREATE_ITEM_REQUEST',
  CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS',
  CREATE_ITEM_ERROR = 'CREATE_ITEM_ERROR',
  RESET_CREATE_ITEM_ERROR = 'RESET_CREATE_ITEM_ERROR',
  RESET_CREATE_ITEM = 'RESET_CREATE_ITEM',
  OPEN_CONFIRMATION_MODAL = 'OPEN_CONFIRMATION_MODAL',
  CLOSE_CONFIRMATION_MODAL = 'CLOSE_CONFIRMATION_MODAL',
  ITEM_FORM_VALIDITY_UPDATE = 'ITEM_FORM_VALIDITY_UPDATE',
  ITEM_SUCCESS_POPUP_CLOSE = 'ITEM_SUCCESS_POPUP_CLOSE',
  ITEM_UPDATE_REQUIRED_FIELDS = 'ITEM_UPDATE_REQUIRED_FIELDS',
  ITEM_UPDATE_FIELDS = 'ITEM_UPDATE_FIELDS',
}

export interface CreateItemInput {
  account?: Account;
  callbackUrl?: string;
  country?: string;
  currency?: Currency;
  endDate?: number;
  fees?: ServiceFees;
  fixedAmount?: number;
  maxAmount?: number;
  maxAmountPerUser?: number;
  maxAvailableQuantity?: number;
  maxQuantityPerUser?: number;
  mcc?: string;
  merchantCategoryCode?: string;
  minAmount?: number;
  name_en: string;
  parentId?: string;
  popularityScore?: number;
  requestId?: string;
  requiredFields?: RequiredServiceField[];
  startDate?: number;
  status?: string;
  supportsDecimals?: boolean;
}

export interface UpdateItemFieldsAction {
  type: CreateItemActionTypes.ITEM_UPDATE_FIELDS;
  payload: CreateItemInput;
}

export interface ResetCreateItemErrorAction {
  type: CreateItemActionTypes.RESET_CREATE_ITEM_ERROR;
}

export interface ResetCreateItemAction {
  type: CreateItemActionTypes.RESET_CREATE_ITEM;
}

export interface CreateItemAction {
  type: CreateItemActionTypes.CREATE_ITEM_REQUEST;
  payload: CreateItemInput;
}

export interface CreateItemSuccessAction {
  type: CreateItemActionTypes.CREATE_ITEM_SUCCESS;
  payload: Item;
}

export interface CreateItemErrorAction {
  type: CreateItemActionTypes.CREATE_ITEM_ERROR;
  error: Error;
}

export interface OpenConfirmationModalAction {
  type: CreateItemActionTypes.OPEN_CONFIRMATION_MODAL;
}

export interface CloseConfirmationModalAction {
  type: CreateItemActionTypes.CLOSE_CONFIRMATION_MODAL;
}

export interface UpdateFormValidityAction {
  type: CreateItemActionTypes.ITEM_FORM_VALIDITY_UPDATE;
  payload: boolean;
}

export interface CloseSuccessPopupAction {
  type: CreateItemActionTypes.ITEM_SUCCESS_POPUP_CLOSE;
}

export interface UpdateRequiredFieldsAction {
  type: CreateItemActionTypes.ITEM_UPDATE_REQUIRED_FIELDS;
  index: number;
  field: keyof RequiredServiceField;
  value: string;
}

export type CreateItemActions =
  | CreateItemAction
  | CreateItemSuccessAction
  | CreateItemErrorAction
  | ResetCreateItemErrorAction
  | ResetCreateItemAction
  | OpenConfirmationModalAction
  | CloseConfirmationModalAction
  | UpdateFormValidityAction
  | CloseSuccessPopupAction
  | UpdateItemFieldsAction
  | UpdateRequiredFieldsAction;

export function createItem(payload: CreateItemInput): CreateItemAction {
  return {
    type: CreateItemActionTypes.CREATE_ITEM_REQUEST,
    payload,
  };
}

export function createItemSuccess(payload: Item): CreateItemSuccessAction {
  return {
    type: CreateItemActionTypes.CREATE_ITEM_SUCCESS,
    payload,
  };
}

export function createItemError(error: Error): CreateItemErrorAction {
  return {
    type: CreateItemActionTypes.CREATE_ITEM_ERROR,
    error,
  };
}

export function resetCreateItemError(): ResetCreateItemErrorAction {
  return {
    type: CreateItemActionTypes.RESET_CREATE_ITEM_ERROR,
  };
}

export function resetCreateItem(): ResetCreateItemAction {
  return {
    type: CreateItemActionTypes.RESET_CREATE_ITEM,
  };
}

export function openConfirmationModal(): OpenConfirmationModalAction {
  return {
    type: CreateItemActionTypes.OPEN_CONFIRMATION_MODAL,
  };
}

export function closeConfirmationModal(): CloseConfirmationModalAction {
  return {
    type: CreateItemActionTypes.CLOSE_CONFIRMATION_MODAL,
  };
}

export function updateFormValidity(isValid: boolean): UpdateFormValidityAction {
  return {
    type: CreateItemActionTypes.ITEM_FORM_VALIDITY_UPDATE,
    payload: isValid,
  };
}

export function closeSuccessPopup(): CloseSuccessPopupAction {
  return {
    type: CreateItemActionTypes.ITEM_SUCCESS_POPUP_CLOSE,
  };
}

export function updateRequiredField(
  index: number,
  field: keyof RequiredServiceField,
  value: string,
): UpdateRequiredFieldsAction {
  return {
    type: CreateItemActionTypes.ITEM_UPDATE_REQUIRED_FIELDS,
    index,
    field,
    value,
  };
}


export function updateItemFields(item: CreateItemInput) {
  return {
    type: CreateItemActionTypes.ITEM_UPDATE_FIELDS,
    payload: item,
  };
}
