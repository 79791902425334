// External dependencies
import { filter, switchMap } from 'rxjs/operators';

// Local dependencies
import { getClient } from '../../../../clients/averspay';
import { getItemFromLocalStorage } from '../../../common/helpers';
import { FeeEntity, SetServiceFeesInput } from '../types';
import {
  ServiceFeesActionTypes,
  SetServiceFeesActions,
  createServiceFeesFailed,
  createServiceFeesSucceeded,
} from './actions';
import { setServiceFeesMutation } from './mutation';

export default function createServiceFeesEpic(actions$: any, state$: any) {
  return actions$.pipe(
    filter((action: SetServiceFeesActions) => action.type === ServiceFeesActionTypes.CREATE_SERVICE_FEES_REQUEST),
    switchMap(() => {
      const { flatFee, flatFeePercent, dynamicFeeSteps } = state$.value.serviceUpperFees;
      const {
        flatFee: lowerFlatFee,
        flatFeePercent: lowerFlatFeePercent,
        dynamicFeeSteps: lowerDynamicFeeSteps,
      } = state$.value.serviceLowerFees;
      const { selectedUsers } = state$.value.users;
      const { selectedAccounts } = state$.value.accounts;
      const { selectedTerminals, entityType } = state$.value.terminals;

      const services = JSON.parse(getItemFromLocalStorage('selectedServices'));

      const feeEntity = {
        [FeeEntity.USER]: selectedUsers,
        [FeeEntity.TERMINAL]: selectedTerminals,
        [FeeEntity.ACCOUNT]: selectedAccounts,
        [FeeEntity.SERVICE]: [],
      };

      const selectedItems = feeEntity[entityType];

      const serviceFees = selectedItems?.reduce((acc, item) => {
        return acc.concat(
          services.map((service) => {
            return {
              id: item.id,
              serviceId: service.id,
              entityType: entityType,
              upper: {
                flatFee,
                flatFeePercent,
                dynamicFeeSteps,
              },
              lower: {
                flatFee: lowerFlatFee,
                flatFeePercent: lowerFlatFeePercent,
                dynamicFeeSteps: lowerDynamicFeeSteps,
              },
            };
          }),
        );
      }, []);

      if (!serviceFees?.length) {
        const serviceFees = services.map((service) => {
          return {
            id: service.feeId ?? 'default',
            serviceId: service.id,
            entityType: entityType,
            upper: {
              flatFee,
              flatFeePercent,
              dynamicFeeSteps,
            },
            lower: {
              flatFee: lowerFlatFee,
              flatFeePercent: lowerFlatFeePercent,
              dynamicFeeSteps: lowerDynamicFeeSteps,
            },
          };
        });

        return setServiceFees(serviceFees).then(createServiceFeesSucceeded).catch(createServiceFeesFailed);
      }

      return setServiceFees(serviceFees).then(createServiceFeesSucceeded).catch(createServiceFeesFailed);
    }),
  );
}

async function setServiceFees(serviceFees: SetServiceFeesInput[]) {
  const graphqlClient = await getClient();

  const result = await graphqlClient.mutate({
    mutation: setServiceFeesMutation,
    variables: {
      input: serviceFees,
    },
  });

  return result as any;
}
