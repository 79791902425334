/* Local dependencies */
import jsonEN from '../src/locales/en/translation.json';
import jsonKY from '../src/locales/ky/translation.json';
import jsonRU from '../src/locales/ru/translation.json';

enum Locales {
  RU = 'ru-RU',
  KY = 'ky-KG',
  EN = 'en-US',
}
export const SUPPORTED_LOCALES = [Locales.RU, Locales.KY, Locales.EN];
const DEFAULT_LOCALE = Locales.EN;

export function getLabelFromTranslation(name: string, locale: string) {
  switch (locale) {
    case Locales.RU:
      return jsonRU[name];
    case Locales.KY:
      return jsonKY[name];
    case Locales.EN:
      return jsonEN[name];
    default:
      return name;
  }
}

export function getLocale(locale) {
  return SUPPORTED_LOCALES.includes(locale) ? locale : DEFAULT_LOCALE;
}

export interface User {
  username?: string;
  password?: string;
  role: UserRole;
}

export enum UserRole {
  AVERSPAY_USER = 'User',
  ADMIN = 'ADMIN',
  ACCOUNTANT = 'ACCOUNTANT',
  MANAGER = 'MANAGER',
  OPERATOR = 'OPERATOR',
  COMPLIANCE_MANAGER = 'COMPLIANCEMANAGER',
  USER = 'USER',
  DEALER = 'DEALER',
  CASHIER = 'CASHIER',
  TECHNICIAN = 'TECHNICIAN',
}

const regex = /\d+/g;
const testPhoneNumbers: string[] = '777007247,709745440,505007247,700123890,507228848'.match(regex);

class UserClass {
  username: string;
  password: string;
  role: UserRole;

  constructor(username: string, role: UserRole, password: string = '529673') {
    this.password = password;
    this.username = username;
    this.role = role;
  }
}

export const users: User[] = [
  new UserClass(testPhoneNumbers[0], UserRole.ADMIN),
  new UserClass(testPhoneNumbers[1], UserRole.ACCOUNTANT),
  new UserClass(testPhoneNumbers[2], UserRole.OPERATOR),
  new UserClass(testPhoneNumbers[3], UserRole.MANAGER),
  new UserClass(testPhoneNumbers[4], UserRole.COMPLIANCE_MANAGER),
  new UserClass(testPhoneNumbers[5], UserRole.CASHIER),
];
