/* Local dependencies */
import { Transaction, TransactionStatus, TransactionType } from './types';

export enum ListTransactionsActionTypes {
  LIST_TRANSACTIONS_REQUEST = 'LIST_TRANSACTIONS_REQUEST',
  LIST_TRANSACTIONS_USER_REQUEST = 'LIST_TRANSACTIONS_USER_REQUEST',
  LIST_TRANSACTIONS_SUCCESS = 'LIST_TRANSACTIONS_SUCCESS',
  LIST_TRANSACTIONS_ERROR = 'LIST_TRANSACTIONS_ERROR',
  LIST_TRANSACTIONS_ERROR_RESET = 'LIST_TRANSACTIONS_ERROR_RESET',
  SORT_TRANSACTIONS_BY = 'SORT_TRANSACTIONS_BY',
  SET_TRANSACTIONS_START_DATE = 'SET_TRANSACTIONS_START_DATE',
  SET_TRANSACTIONS_END_DATE = 'SET_TRANSACTIONS_END_DATE',
  DEBIT_PAYMENT_TYPE = 'DEBIT_PAYMENT_TYPE',
  TOGGLE_TABLE_MODE = 'TOGGLE_TABLE_MODE',
}

export interface ListTransactionsInput {
  startDate?: Date;
  startTime?: string;
  endDate?: Date;
  endTime?: string;
  locale?: string;
  searchString?: string;
  currentPage?: number;
  filter?: ListTransactionsFilter;
  size?: number;
}

export interface ListTransactions {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST;
  startDate?: string;
  endDate?: string;
  locale?: string;
  searchString?: string;
  currentPage?: number;
  filter?: ListTransactionsFilter;
  size?: number;
}

export interface ListTransactionsUser {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_USER_REQUEST;
  startDate?: Date;
  endDate?: Date;
  locale?: string;
  searchString?: string;
  currentPage?: number;
  filter?: ListTransactionsFilter;
}

export interface ListTransactionsSuccess {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_SUCCESS;
  payments: Transaction[];
  total: number;
  totalAmount: number;
}

export interface ListTransactionsError {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR;
  error: Error;
}

export interface ListTransactionsErrorReset {
  type: ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR_RESET;
}

export interface ListTransactionsFilter {
  accountId?: string;
  status?: TransactionStatus[];
  type?: TransactionType;
  userId?: string;
  service?: { id: string };
}

export const DEFAULT_PAYMENT_TYPE: ListTransactionsFilter = {
  type: TransactionType.CREDIT,
};

export const DEBIT_PAYMENT_TYPE: ListTransactionsFilter = {
  type: TransactionType.DEBIT,
};

export interface TransactionsStartDate {
  type: ListTransactionsActionTypes.SET_TRANSACTIONS_START_DATE;
  startDate: Date;
}

export interface TransactionsEndDate {
  type: ListTransactionsActionTypes.SET_TRANSACTIONS_END_DATE;
  endDate: Date;
}

export interface ToggleTableMode {
  type: ListTransactionsActionTypes.TOGGLE_TABLE_MODE;
  isDeveloperMode?: boolean;
}

export type ListTransactionsAction =
  | ListTransactions
  | ListTransactionsUser
  | ListTransactionsSuccess
  | ListTransactionsError
  | ListTransactionsErrorReset
  | TransactionsStartDate
  | TransactionsEndDate
  | ToggleTableMode;

export function listTransactions({
  locale,
  searchString = '',
  currentPage = 1,
  filter,
  startDate,
  endDate,
  startTime,
  endTime,
  size,
}: ListTransactionsInput): ListTransactions {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_REQUEST,
    locale,
    searchString,
    currentPage,
    filter,
    startDate,
    startTime,
    endDate,
    endTime,
    size,
  };
}

export function listTransactionsUser(
  locale?: string,
  searchString?: string = '',
  currentPage?: number = 1,
  filter: ListTransactionsFilter,
  startDate?: Date,
  endDate?: Date,
): ListTransactionsUser {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_USER_REQUEST,
    locale,
    searchString,
    currentPage,
    filter,
    startDate,
    endDate,
  };
}

export function listTransactionsSucceeded(total, payments, totalAmount): ListTransactionsSuccess {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_SUCCESS,
    payments,
    total,
    totalAmount,
  };
}

export function listTransactionsFailed(error: Error): ListTransactionsError {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR,
    error,
  };
}

export function listTransactionsFailedReset(): ListTransactionsErrorReset {
  return {
    type: ListTransactionsActionTypes.LIST_TRANSACTIONS_ERROR_RESET,
  };
}

export function sortTransactionsBy(field: string) {
  return {
    type: ListTransactionsActionTypes.SORT_TRANSACTIONS_BY,
    field,
  };
}

export function setStartDate(startDate: Date): TransactionsStartDate {
  return {
    type: ListTransactionsActionTypes.SET_TRANSACTIONS_START_DATE,
    startDate,
  };
}

export function setEndDate(endDate: Date): TransactionsEndDate {
  return {
    type: ListTransactionsActionTypes.SET_TRANSACTIONS_END_DATE,
    endDate,
  };
}

export function toggleTableMode(isDeveloperMode?: boolean): ToggleTableMode {
  return {
    type: ListTransactionsActionTypes.TOGGLE_TABLE_MODE,
    isDeveloperMode,
  };
}
