/* External dependencies */
import gql from 'graphql-tag';

export const createUserWithCorporateAccountMutation = gql`
  mutation createUserWithCorporateAccount($input: CreateUserWithCorporateAccountInput!) {
    createUserWithCorporateAccount(input: $input) {
      id
      firstName
      lastName
      phone
    }
  }
`;
