/* External dependencies */
import { gql as gqlApollo } from '@apollo/client';
import gql from 'graphql-tag';

export const listServicesQuery = gql`
  query listServices($input: ListServicesInput!) {
    listServices(input: $input) {
      total
      services {
        typename: __typename
        ... on Category {
          id
          name_en
          name_ru
          name_ky
          status
          logo {
            url
          }
          popularityScore
          parents {
            id
            name_en
            name_ru
            name_ky
          }
          parentId
        }
        ... on Service {
          id
          name_en
          name_ru
          name_ky
          logo {
            url
          }
          status
          popularityScore
          parents {
            id
            name_en
            name_ru
            name_ky
          }
          parentId
        }
        ... on Item {
          id
          userId
          maxAvailableQuantity
          account {
            id
            name
          }
          qrCode {
            image
            url
          }
          name_en
          fixedAmount
          startDate
          endDate
          name_ru
          name_ky
          logo {
            url
          }
          status
          popularityScore
        }
      }
    }
  }
`;

export const listServicesSearchQuery = gql`
  query listServices($query: String!, $locale: Locale!) {
    listServices(input: { query: $query, locale: $locale }) {
      total
      services {
        typename: __typename
        ... on Service {
          id
          name_en
          name_ru
          name_ky
          logo {
            url
          }
        }
      }
    }
  }
`;

export const listServicesAndCategoriesQuery = gqlApollo`
  query listServices($input: ListServicesInput!) {
    listServices(input: $input) {
      total
      services {
        typename: __typename
        ... on Category {
          id
          name_en
          name_ru
          name_ky
          status
          logo {
            url
          }
          popularityScore
          parents {
            id
            name_en
            name_ru
            name_ky
          }
          parentId
        }
        ... on Service {
          id
          name_en
          name_ru
          name_ky
          logo {
            url
          }
          status
          popularityScore
          parents {
            id
            name_en
            name_ru
            name_ky
          }
          parentId
        }
      }
    }
  }
`;

export const listPopularServicesQuery = gqlApollo`
  query listPopularServices($locale: Locale!) {
    listServices(input: { filter: { parentId: "" }, locale: $locale }) {
      services {
        typename: __typename
        ... on Service {
          id
          name_ky
          name_ru
          name_en
          parentId
          __typename
          logo {
            url
          }
        }
      }
    }
  }
`;
