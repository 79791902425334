// Local dependencies
import { GetItem, GetItemActionTypes, GetItemError, GetItemSuccess, ItemQrCode, ResetItem } from './types';

export function getItem(id: string): GetItem {
  return {
    id,
    type: GetItemActionTypes.GET_ITEM_REQUEST,
  };
}

export function getItemSuccess(item: ItemQrCode): GetItemSuccess {
  return {
    type: GetItemActionTypes.GET_ITEM_SUCCESS,
    item,
  };
}

export function getItemError(error: Error): GetItemError {
  return {
    type: GetItemActionTypes.GET_ITEM_ERROR,
    error,
  };
}

export function resetItem(): ResetItem {
  return {
    type: GetItemActionTypes.RESET_ITEM,
  };
}
