/* Local dependencies */
import { Category, Item, Service, ServiceStatus } from '../types';

export enum ListServicesActionTypes {
  LIST_SERVICES_REQUEST = 'LIST_SERVICES_REQUEST',
  LIST_SERVICES_SUCCESS = 'LIST_SERVICES_SUCCESS',
  LIST_SERVICES_ERROR = 'LIST_SERVICES_ERROR',
  RESET_LIST_SERVICES_ERROR = 'RESET_LIST_SERVICES_ERROR',
  RESET_SELECTED_SERVICE = 'RESET_SELECTED_SERVICE',
  SELECT_SERVICE = 'SELECT_SERVICE',
  SORT_SERVICES_BY = 'SORT_SERVICES_BY',
  RESET_SERVICES_STATE = 'RESET_SERVICES_STATE',
  PRESS_SERVICE = 'PRESS_SERVICE',
  RESET_PRESS_SERVICE = 'RESET_PRESS_SERVICE',
  GET_LOCALE = 'GET_LOCALE',
  DELETE_SERVICE_POPUP = 'DELETE_SERVICE_POPUP',
}

export interface ListServices {
  type: ListServicesActionTypes.LIST_SERVICES_REQUEST;
  currentPage?: number;
  filter?: ListServicesFilter;
  locale?: string;
  searchString?: string;
  size?: number;
  startDate?: Date;
  endDate?: Date;
}

export interface ListServicesSuccess {
  type: ListServicesActionTypes.LIST_SERVICES_SUCCESS;
  services: Service[] | Category[];
  total: number;
}

export interface ListServicesError {
  type: ListServicesActionTypes.LIST_SERVICES_ERROR;
  error: Error;
}

export interface ListServicesFilter {
  parentId?: string;
  status?: [ServiceStatus];
  typename?: ServiceType;
  userId?: string;
}

export const DEFAULT_SERVICE_FILTER: ListServicesFilter = {
  parentId: 'kyrgyzstan',
};

export interface ListServicesSort {
  type: ListServicesActionTypes.SORT_SERVICES_BY;
  field: string;
}

export interface SelectService {
  type: ListServicesActionTypes.SELECT_SERVICE;
  service: Service | Category | Item;
}

export interface ResetSelectedService {
  type: ListServicesActionTypes.RESET_SELECTED_SERVICE;
}

export interface ResetServicesState {
  type: ListServicesActionTypes.RESET_SERVICES_STATE;
}

export interface ResetListServicesError {
  type: ListServicesActionTypes.RESET_LIST_SERVICES_ERROR;
}

export interface PressService {
  type: ListServicesActionTypes.PRESS_SERVICE;
  service: Service | Category;
}

export interface ResetPressService {
  type: ListServicesActionTypes.RESET_PRESS_SERVICE;
}

export interface GetLocale {
  type: ListServicesActionTypes.GET_LOCALE;
  locale: string;
}

export type ListServicesAction =
  | GetLocale
  | ListServices
  | ListServicesError
  | ListServicesSuccess
  | PressService
  | ResetPressService
  | ResetSelectedService
  | ResetServicesState
  | ResetListServicesError
  | SelectService;

export enum ServiceType {
  CATEGORY = 'Category',
  SERVICE = 'Service',
  ITEM = 'Item',
}

export function listServices(
  filter?: {},
  currentPage: number = 1,
  locale?: string,
  searchString: string = '',
  size: number = 20,
  startDate?: Date,
  endDate?: Date,
): ListServices {
  return {
    type: ListServicesActionTypes.LIST_SERVICES_REQUEST,
    filter,
    currentPage,
    locale,
    searchString,
    size,
    startDate,
    endDate,
  };
}

export function listServicesSucceeded(total, services): ListServicesSuccess {
  return {
    type: ListServicesActionTypes.LIST_SERVICES_SUCCESS,
    services,
    total,
  };
}

export function listServicesFailed(error: Error): ListServicesError {
  return {
    type: ListServicesActionTypes.LIST_SERVICES_ERROR,
    error,
  };
}

export function sortServicesBy(field: string) {
  return {
    type: ListServicesActionTypes.SORT_SERVICES_BY,
    field,
  };
}

export function selectService(service: Service | Category | Item): SelectService {
  return {
    type: ListServicesActionTypes.SELECT_SERVICE,
    service,
  };
}

export function resetSelectedService() {
  return {
    type: ListServicesActionTypes.RESET_SELECTED_SERVICE,
  };
}

export function resetServicesState() {
  return {
    type: ListServicesActionTypes.RESET_SERVICES_STATE,
  };
}

export function resetListServicesError(): ResetListServicesError {
  return {
    type: ListServicesActionTypes.RESET_LIST_SERVICES_ERROR,
  };
}

export function pressedService(service: Service | Category): PressService {
  return {
    type: ListServicesActionTypes.PRESS_SERVICE,
    service,
  };
}

export function resetPressedService(): ResetPressService {
  return {
    type: ListServicesActionTypes.RESET_PRESS_SERVICE,
  };
}

export function getLocale(locale: string): GetLocale {
  return {
    type: ListServicesActionTypes.GET_LOCALE,
    locale,
  };
}
