/* Local dependencies */
import { MakePaymentInput } from '../types';

export enum MakePaymentActionTypes {
  MAKE_PAYMENT_REQUEST = 'MAKE_PAYMENT_REQUEST',
  MAKE_PAYMENT_SUCCESS = 'MAKE_PAYMENT_SUCCESS',
  MAKE_PAYMENT_ERROR = 'MAKE_PAYMENT_ERROR',
  RESET_MAKE_PAYMENT_STATE = 'RESET_MAKE_PAYMENT_STATE',
  RESET_MAKE_PAYMENT_ERROR = 'RESET_MAKE_PAYMENT_ERROR',
}

export interface MakePaymentRequest {
  type: MakePaymentActionTypes.MAKE_PAYMENT_REQUEST;
  payload: MakePaymentInput;
}

export interface MakePaymentSuccess {
  type: MakePaymentActionTypes.MAKE_PAYMENT_SUCCESS;
  paymentData: MakePaymentInput;
}

export interface MakePaymentError {
  type: MakePaymentActionTypes.MAKE_PAYMENT_ERROR;
  error: Error;
}

export interface ResetMakePaymentState {
  type: MakePaymentActionTypes.RESET_MAKE_PAYMENT_STATE;
}

export interface ResetMakePaymentError {
  type: MakePaymentActionTypes.RESET_MAKE_PAYMENT_ERROR;
}

export type MakePaymentAction =
  | MakePaymentRequest
  | MakePaymentSuccess
  | MakePaymentError
  | ResetMakePaymentState
  | ResetMakePaymentError;

export function makePaymentRequest(payload: MakePaymentInput): MakePaymentRequest {
  return {
    type: MakePaymentActionTypes.MAKE_PAYMENT_REQUEST,
    payload,
  };
}

export function makePaymentSuccess(paymentData: any): MakePaymentSuccess {
  return {
    type: MakePaymentActionTypes.MAKE_PAYMENT_SUCCESS,
    paymentData,
  };
}

export function makePaymentError(error: Error): MakePaymentError {
  return {
    type: MakePaymentActionTypes.MAKE_PAYMENT_ERROR,
    error,
  };
}

export function resetMakePaymentState(): ResetMakePaymentState {
  return {
    type: MakePaymentActionTypes.RESET_MAKE_PAYMENT_STATE,
  };
}

export function resetMakePaymentError(): ResetMakePaymentError {
  return {
    type: MakePaymentActionTypes.RESET_MAKE_PAYMENT_ERROR,
  };
}
